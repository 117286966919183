import { Form, Formik } from 'formik';
import Image from 'next/image';
import { match } from 'ts-pattern';
import { formatUnits, parseUnits } from 'viem';
import { string, z } from 'zod';

import { GetToken, GetTokenPrice } from '@endaoment-frontend/api';
import { config, featureFlags } from '@endaoment-frontend/config';
import { STABLECOIN_DECIMALS, TERMS_AND_CONDITIONS_URL } from '@endaoment-frontend/constants';
import { TargetAllocationRebalanceCheckbox } from '@endaoment-frontend/target-allocations';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { Input, validateWithZod } from '@endaoment-frontend/ui/forms';
import { Button, CopyTooltip, Loader } from '@endaoment-frontend/ui/shared';

import styles from '../DonationWizard.module.scss';
import { RequestLiquidationCheckbox } from '../common/RequestLiquidationCheckbox';
import { TaxReceiptButton } from '../common/TaxReceiptButton';

import { OtcQRCodeModal } from './otcQRCodeModal/OtcQRCodeModal';

const MIN_HASH_LENGTH = 5;
const transactionInputFormSchema = z.object({
  transactionHash: string().refine(v => v.length >= MIN_HASH_LENGTH, "The transaction's hash is required"),
});

type OtcTransactionInputProps = {
  recipient: DonationRecipient;
  tokenId: number;
  tokenAmount: bigint;
  donorIdentity: { taxReceipt: false; donorEmail?: undefined } | { taxReceipt: true; donorEmail: string };
  isProcessing: boolean;
  otcDonationTransactionHash: string;
  onSubmit: (transactionHash: string) => void;
  isRebalanceRequested: boolean;
  onChangeRebalanceRequested: (newVal: boolean) => void;
  requestScheduledLiquidation: boolean;
  onChangeRequestScheduledLiquidation: (newVal: boolean) => void;
  onGoToTaxStep: (transactionHash: string) => void;
  onNoTaxReceipt: () => void;
  disclaimerAcknowledged: boolean;
  onDisclaimerAcknowledged: (newVal: boolean) => void;
};

export const OtcTransactionInput = ({
  recipient,
  tokenId,
  tokenAmount,
  donorIdentity,
  isProcessing,
  otcDonationTransactionHash,
  onSubmit,
  isRebalanceRequested,
  onChangeRebalanceRequested,
  requestScheduledLiquidation,
  onChangeRequestScheduledLiquidation,
  onGoToTaxStep,
  onNoTaxReceipt,
  disclaimerAcknowledged,
  onDisclaimerAcknowledged,
}: OtcTransactionInputProps) => {
  const { data: token } = GetToken.useQuery([tokenId]);
  const { data: tokenPrice } = GetTokenPrice.useQuery([tokenId]);

  return (
    <>
      <div className={styles['donation-info']}>
        <div>
          <h4>Expected Donation</h4>
          <h4>
            {!!token && (
              <div>
                {!!token.logoUrl && (
                  <Image src={token.logoUrl} alt={`${token.name} token icon`} width={20} height={20} />
                )}
                {`${formatUnits(tokenAmount, token.decimals)} ${token.symbol}`}
              </div>
            )}
          </h4>
        </div>
        {!!featureFlags.scheduledLiquidation && (
          <RequestLiquidationCheckbox
            requestScheduledLiquidation={requestScheduledLiquidation}
            onChangeRequestScheduledLiquidation={onChangeRequestScheduledLiquidation}
          />
        )}
        {!!tokenPrice && (
          <TargetAllocationRebalanceCheckbox
            isRebalanceRequested={isRebalanceRequested}
            onChange={onChangeRebalanceRequested}
            recipient={recipient}
            // TODO: Check if this is the right way to calculate the additional balance
            additionalBalance={parseUnits(`${tokenPrice}`, STABLECOIN_DECIMALS) * tokenAmount}
          />
        )}
      </div>
      <hr />
      {!!token &&
        match({ isProcessing, disclaimerAcknowledged })
          .with({ isProcessing: true }, () => (
            <div className={styles['column']}>
              <Loader />
            </div>
          ))
          .with({ disclaimerAcknowledged: false }, () => (
            <div className={styles['column']}>
              <p className={styles['disclaimer']}>
                Please acknowledge{' '}
                <a
                  target='_blank'
                  href='https://docs.endaoment.org/governance/documentation/terms-and-conditions#11-a-over-the-counter-donation-disclaimer'
                  rel='noreferrer'>
                  our disclaimer
                </a>{' '}
                for over-the-counter donations.
              </p>
              <Button variation='org' filled onClick={() => onDisclaimerAcknowledged(true)}>
                I understand
              </Button>
            </div>
          ))
          .otherwise(() => {
            const tokenSendAddress = match(token)
              .with({ type: 'OtcToken' }, token => token.otcAddress)
              .with({ type: 'EvmToken' }, () => config.endaoment.accounts.accountant[0])
              .exhaustive();
            return (
              <Formik
                className={styles['transaction-instructions']}
                initialValues={{ transactionHash: otcDonationTransactionHash }}
                enableReinitialize
                onSubmit={v => onSubmit(v.transactionHash)}
                validate={validateWithZod(transactionInputFormSchema)}>
                {({ errors, values, setFieldValue, setFieldTouched, isValid }) => (
                  <Form className={styles['step-form']}>
                    <Input
                      value={tokenSendAddress}
                      readOnly
                      label={
                        <>
                          Please send&nbsp;<b>{`${formatUnits(tokenAmount, token.decimals)} ${token.symbol}`}</b>
                          &nbsp;in one transaction to this address:
                        </>
                      }
                      className={styles['otc-address']}
                      rightElements={
                        <>
                          <OtcQRCodeModal token={token} tokenSendAddress={tokenSendAddress} />
                          <CopyTooltip displayText='Copy Address' copyText={tokenSendAddress} icon='link' />
                        </>
                      }
                    />
                    {token.type === 'OtcToken' && !!token.memo && (
                      <Input
                        value={token.memo}
                        readOnly
                        label='With the following memo'
                        className={styles['memo-input']}
                        rightElements={<CopyTooltip displayText='Copy Memo' copyText={token.memo} icon='link' />}
                      />
                    )}
                    <br />
                    <Input
                      name='transactionHash'
                      value={values.transactionHash}
                      label='Then, enter your transaction hash'
                      onChange={e => setFieldValue('transactionHash', e.target.value)}
                      onBlur={() => setFieldTouched('transactionHash', true)}
                      error={errors.transactionHash}
                      placeholder='Transaction Hash'
                    />
                    <TaxReceiptButton
                      includeTaxReceipt={!!donorIdentity.taxReceipt}
                      receiptEmail={donorIdentity.donorEmail}
                      onClick={nextStep => {
                        if (!nextStep) {
                          onNoTaxReceipt();
                          return;
                        }
                        onGoToTaxStep(values.transactionHash);
                      }}
                    />
                    <div className={styles['submit-and-toc']}>
                      <Button type='submit' disabled={!isValid} variation='org' filled>
                        Submit
                      </Button>
                      <p>
                        I agree to Endaoment’s&nbsp;
                        <a href={TERMS_AND_CONDITIONS_URL} target='_blank' rel='noreferrer'>
                          Terms and Conditions
                        </a>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            );
          })}
    </>
  );
};
