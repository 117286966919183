import type { PrivyEvents } from '@privy-io/react-auth';
import { PrivyProvider, useLogin, useLogout, useWallets } from '@privy-io/react-auth';
import { createConfig, useEmbeddedSmartAccountConnector, WagmiProvider } from '@privy-io/wagmi';
import { signerToEcdsaValidator } from '@zerodev/ecdsa-validator';
import { ZeroDevProvider } from '@zerodev/privy';
import {
  createKernelAccount,
  createKernelAccountClient,
  createZeroDevPaymasterClient,
  KernelEIP1193Provider,
  type KernelAccountClient,
} from '@zerodev/sdk';
import { providerToSmartAccountSigner } from 'permissionless';
import { type ReactNode } from 'react';
import { useAsync, useEvent } from 'react-use';
import { createPublicClient, http, type EIP1193Provider } from 'viem';
import { useAccountEffect, useConfig } from 'wagmi';

import { SignOut, WhoAmI } from '@endaoment-frontend/api';
import {
  GetPrivyWalletAddressForUser,
  useAuthType,
  useSignOut,
  ZERODEV_ENTRY_POINT,
  ZERODEV_KERNEL_VERSION,
} from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { WALLET_CONNECT_PROJECT_ID } from '@endaoment-frontend/constants';
import { defaultQueryClient, RequestHandler } from '@endaoment-frontend/data-fetching';
import {
  createHttpTransport,
  createTransportsForChains,
  getChainForChainId,
  getConnectors,
} from '@endaoment-frontend/multichain';
import { convertCAIP2ToChainId } from '@endaoment-frontend/utils';

import { makeAuthCycleHandler } from './makeAuthCycleHandler';

const connectors = getConnectors({
  appName: 'app.endaoment',
  appDescription: 'Endaoment',
  appUrl: 'https://app.endaoment.org',
  appLogo: 'https://storage.googleapis.com/endaoment-static/privy-logo-header.png',
});
const transports = createTransportsForChains(config.chains);
const wagmiConfig = createConfig({
  chains: config.chains,
  connectors,
  transports,
  batch: {
    multicall: true,
  },
  syncConnectedChain: true,

  // These settings are required for the Privy SDK
  ssr: true,
  multiInjectedProviderDiscovery: false,
});

/** Wrapper for WAGMI with our custom configs */
export const NdaoPrivyProvider = ({ children }: { children: Array<ReactNode> | ReactNode }) => {
  return (
    <ZeroDevProvider projectId={config.zeroDev.projectId} onlySendSponsoredTransaction>
      <PrivyProvider
        appId={config.privyAppId}
        config={{
          // Customize Privy's appearance in your app
          appearance: {
            logo: 'https://storage.googleapis.com/endaoment-static/privy-logo-header.png',
            theme: 'light',
            accentColor: '#676FFF',
            walletList: ['detected_wallets', 'metamask', 'coinbase_wallet', 'wallet_connect'],
            showWalletLoginFirst: false,
            landingHeader: 'Connect to continue',
            walletChainType: 'ethereum-only',
          },
          legal: {
            privacyPolicyUrl: 'https://docs.endaoment.org/governance/documentation/personal-data-usage-and-storage',
            termsAndConditionsUrl: 'https://docs.endaoment.org/governance/documentation/terms-and-conditions',
          },
          // Create embedded wallets for users who don't have a wallet
          embeddedWallets: {
            createOnLogin: 'users-without-wallets',
          },
          supportedChains: config.chains,
          walletConnectCloudProjectId: WALLET_CONNECT_PROJECT_ID,
        }}>
        <WagmiProvider config={wagmiConfig}>
          <PrivyAuthHandleProvider />
          <PrivyAccountChangeEnforcer />
          <PrivySocialChainEnforcer />
          {children}
        </WagmiProvider>
      </PrivyProvider>
    </ZeroDevProvider>
  );
};

const getSmartAccountFromSigner = async ({
  signer: privyProvider,
}: {
  signer: EIP1193Provider;
}): Promise<EIP1193Provider> => {
  const socialChain = getChainForChainId(config.socialLoginChainId);
  const tempClient = createPublicClient({
    chain: socialChain,
    transport: createHttpTransport(socialChain.rpcUrls.default.http[0]),
  });

  const smartAccountSigner = await providerToSmartAccountSigner(privyProvider);

  // https://docs.zerodev.app/sdk/presets/zerodev#ecdsa-validator
  const ecdsaValidator = await signerToEcdsaValidator(tempClient, {
    signer: smartAccountSigner,
    entryPoint: ZERODEV_ENTRY_POINT,
    kernelVersion: ZERODEV_KERNEL_VERSION,
  });
  const kernelAccount = await createKernelAccount(tempClient, {
    plugins: {
      sudo: ecdsaValidator,
    },
    entryPoint: ZERODEV_ENTRY_POINT,
    kernelVersion: ZERODEV_KERNEL_VERSION,
    index: 0n,
    chainId: socialChain.id,
  });
  const kernelClient = createKernelAccountClient({
    account: kernelAccount,
    chain: socialChain,
    entryPoint: ZERODEV_ENTRY_POINT,
    // Find the Bundler RPC on the ZeroDev dashboard
    bundlerTransport: http(config.zeroDev.bundlerRpcUrl),
    middleware: {
      sponsorUserOperation: async ({ userOperation, entryPoint }) => {
        const zerodevPaymaster = createZeroDevPaymasterClient({
          chain: socialChain,
          entryPoint,
          // Find the Paymaster RPC on the ZeroDev dashboard
          transport: http(config.zeroDev.paymasterRpcUrl),
        });
        return zerodevPaymaster.sponsorUserOperation({
          // Stupid ZeroDev SDK doesn't have types for this

          userOperation,
          entryPoint,
        });
      },
    },
  });
  const kernelEIP1193Provider = new KernelEIP1193Provider(
    kernelClient as KernelAccountClient<typeof ZERODEV_ENTRY_POINT>,
  );
  return kernelEIP1193Provider as EIP1193Provider;
};
const PrivyAuthHandleProvider = () => {
  const wagmiConfig = useConfig();
  const signOut = useSignOut();

  // This hook is normally used to show the login modal, but it is used here to trigger actions after login is complete
  // It should only be mounted with the callback once since the provided callback is called on each mounted instance
  useLogin({
    onComplete: makeAuthCycleHandler({ wagmiConfig, signOut }),
    onError: () => {
      void signOut();
    },
  });

  // This hook is normally used to logout, but it is used here to trigger actions after logout is complete
  const handleLogoutComplete: PrivyEvents['logout']['onSuccess'] = async () => {
    console.info('Logging user out');
    try {
      await SignOut.execute();
    } catch {
      console.warn('User was not logged in on NDAO');
    } finally {
      RequestHandler.resetUserSpecificQueries();
      WhoAmI.setData(defaultQueryClient, [], () => null);
      GetPrivyWalletAddressForUser.setData(defaultQueryClient, [null], () => null);
    }
  };
  useLogout({
    onSuccess: handleLogoutComplete,
  });

  // This hook makes wagmi use the Smart Account for social users only
  useEmbeddedSmartAccountConnector({
    getSmartAccountFromSigner,
  });

  // TODO: Remove this once we stabilize the CSP
  useEvent('securitypolicyviolation', v => {
    console.info('CSP Violation', v);
  });
  return null;
};

const PrivyAccountChangeEnforcer = () => {
  const signOut = useSignOut();

  useAccountEffect({
    onDisconnect: () => {
      console.warn('Account changed, signing out');
      void signOut();
    },
  });

  return null;
};

const PrivySocialChainEnforcer = () => {
  const { authType } = useAuthType();

  const { wallets } = useWallets();
  const currentWallet = wallets[0];

  useAsync(async () => {
    if (authType !== 'social' || !currentWallet) return;

    const currentChainId = convertCAIP2ToChainId(currentWallet.chainId);
    if (currentChainId !== config.socialLoginChainId) return;

    await currentWallet.switchChain(config.socialLoginChainId);
  }, [authType, currentWallet?.chainId]);

  return null;
};
