import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { P, match } from 'ts-pattern';

import { GetPortfolio } from '@endaoment-frontend/api';
import { useAuthType } from '@endaoment-frontend/authentication';
import type { PolymorphicProps, PortfolioFinancial, UUID } from '@endaoment-frontend/types';
import { ChainIcon } from '@endaoment-frontend/ui/icons';
import { formatNumber, getDateStringForPortfolioPerformance } from '@endaoment-frontend/utils';

import { PortfolioCategoryPill } from '../PortfolioGroupCard';

import { MiniLoadingDetails, componentMatch } from './MiniEntityDetails';
import styles from './MiniEntityDetails.module.scss';

type MiniPortfolioDetailsProps<Tag extends As> = PolymorphicProps<
  'div',
  Tag,
  {
    portfolio: Pick<
      PortfolioFinancial,
      | 'cap'
      | 'categories'
      | 'chainId'
      | 'currentApyBps'
      | 'id'
      | 'logoUrl'
      | 'name'
      | 'quarterlyPerformanceBps'
      | 'totalInvestedInPortfolio'
      | 'type'
      | 'underlyingTokenContractAddress'
      | 'updatedAt'
    >;
    padding?: boolean;
  }
>;

export const MiniPortfolioDetails = <Tag extends As>({
  portfolio,
  className,
  padding = true,
  children,
  ...props
}: MiniPortfolioDetailsProps<Tag>) => {
  const { isWalletAuth } = useAuthType();
  const isPositive = portfolio.quarterlyPerformanceBps && portfolio.quarterlyPerformanceBps > 0;

  const dateString = getDateStringForPortfolioPerformance(portfolio);
  return (
    <Box
      className={clsx(
        styles['entity-details'],
        styles['entity-details--portfolio'],
        !padding && styles['entity-details--nopad'],
        className,
      )}
      as={componentMatch(props)}
      {...props}>
      <div className={styles['entity-details__logo']}>
        <Image src={portfolio.logoUrl} alt='' height={100} width={100} />
        {!!isWalletAuth && <ChainIcon chainId={portfolio.chainId} className={styles['chain-icon']} filled light />}
      </div>
      <h6>
        {portfolio.name}
        <div className={styles['entity-details__tags']}>
          {portfolio.categories.slice(0, 2).map(category => (
            <PortfolioCategoryPill key={category.id} category={category} />
          ))}
        </div>
      </h6>
      <div className={styles['entity-details__extra']}>
        <div className={styles['historical-display']}>
          {match(portfolio)
            .with({ quarterlyPerformanceBps: P.not(P.nullish) }, ({ quarterlyPerformanceBps }) => (
              <span>
                <b className={isPositive ? styles['positive'] : styles['negative']}>
                  {!!isPositive && '+'}
                  {formatNumber(quarterlyPerformanceBps / 100, { digits: 2 })}%
                </b>
              </span>
            ))
            .with({ currentApyBps: P.not(P.nullish) }, ({ currentApyBps }) => (
              <span>
                <b>{formatNumber(currentApyBps / 100, { digits: 2 })}%</b>
              </span>
            ))
            .otherwise(() => (
              <span />
            ))}
          <span>{dateString}</span>
        </div>
      </div>
      {children}
    </Box>
  );
};

export const MiniPortfolioDetailsWithQuery = <Tag extends As>({
  portfolioId,
  ...props
}: Omit<MiniPortfolioDetailsProps<Tag>, 'portfolio'> & { portfolioId: UUID }) => {
  const { data: portfolio } = GetPortfolio.useQuery([portfolioId]);

  if (!portfolio) return <MiniLoadingDetails />;

  return <MiniPortfolioDetails portfolio={portfolio} {...props} />;
};
