import { Box } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useMemo } from 'react';
import type { z } from 'zod';

import { GetUserIdentity, UpdateUserIdentity } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { useGoogleLocation, useIsMobile } from '@endaoment-frontend/hooks';
import { phoneNumberSchema, type DonationRecipient, type PhysicalAddress } from '@endaoment-frontend/types';
import {
  CountryFormInput,
  FormCheckbox,
  FormInput,
  ProceedButton,
  StateFormInput,
  ZipFormInput,
  validateWithZod,
} from '@endaoment-frontend/ui/forms';

import { donationTaxInfoSchema } from '../DonationWizard.types';

import styles from './StockDonation.module.scss';

const tgbTaxInfoSchema = donationTaxInfoSchema.extend({
  phone: phoneNumberSchema,
});
export type TgbTaxInfoFormData = z.infer<typeof tgbTaxInfoSchema>;

const BrokerageDonationTaxInfoForm = ({
  donationDestinationType,
}: {
  donationDestinationType: DonationRecipient['type'];
}) => {
  const { setFieldValue } = useFormikContext<TgbTaxInfoFormData>();
  const { ref } = useGoogleLocation(place => setFieldValue('address', place));
  // Check if connected
  const { isSignedIn } = useAuth();

  const { isMobile } = useIsMobile({ defaultState: true });

  return (
    <Form className={styles['form']}>
      <FormInput name='email' label='Email' data-testid='email' autoFocus={!isMobile} />
      <FormInput name='phone' label='Phone Number' data-testid='phone' />
      <div className={styles['split-row']}>
        <FormInput name='firstName' label='First Name' data-testid='firstName' />
        <FormInput name='lastName' label='Last Name' data-testid='lastName' />
      </div>
      <div className={styles['split-row']}>
        <FormInput name='address.line1' label='Address Line 1' innerRef={ref} data-testid='address.line1' />
        <FormInput name='address.line2' label='Address Line 2' data-testid='address.line2' />
      </div>
      <CountryFormInput
        countryFieldName='address.country'
        stateFieldName='address.state'
        data-testid='address.country'
      />
      <div className={styles['split-row']}>
        <StateFormInput name='address.state' countryFieldName='address.country' data-testid='address.state' />
        <ZipFormInput name='address.zip' countryFieldName='address.country' data-testid='address.zip' />
      </div>
      <FormInput name='address.city' label='City' data-testid='address.city' />
      <Box marginTop='1rem'>
        {!!isSignedIn && (
          <FormCheckbox
            name='updateProfile'
            label='Update my profile with this information'
            className={styles['update-checkbox']}
          />
        )}
        {donationDestinationType === 'org' && (
          <FormCheckbox
            name='shareMyEmail'
            label='Share my email with the organization'
            className={styles['update-checkbox']}
          />
        )}
      </Box>
      <ProceedButton type='submit' />
    </Form>
  );
};

export const BrokerageDonationTaxInfo = ({
  donationDestinationType,
  onSubmit,
  initialValues,
}: {
  donationDestinationType: DonationRecipient['type'];
  onSubmit: (v: TgbTaxInfoFormData) => void;
  initialValues?: TgbTaxInfoFormData;
}) => {
  const queryClient = useQueryClient();
  const { isSignedIn } = useAuth();
  const { data: userInfo } = GetUserIdentity.useQuery([], { enabled: isSignedIn });
  const { mutateAsync: updateUserInfo } = useMutation({
    mutationFn: UpdateUserIdentity.execute,
    mutationKey: [UpdateUserIdentity.key],
    onSuccess: () => {
      GetUserIdentity.invalidateQuery(queryClient, []);
    },
  });

  const handleSubmit = async (values: TgbTaxInfoFormData) => {
    if (values.updateProfile) updateUserInfo(values);
    onSubmit(values);
  };

  const computedInitialValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      updateProfile: false,
      shareMyEmail: false,
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
      } as PhysicalAddress,
      ...userInfo,
      ...initialValues,
    }),
    [userInfo, initialValues],
  );

  return (
    <Formik
      initialValues={computedInitialValues}
      enableReinitialize
      validate={validateWithZod(tgbTaxInfoSchema)}
      onSubmit={handleSubmit}>
      <BrokerageDonationTaxInfoForm donationDestinationType={donationDestinationType} />
    </Formik>
  );
};
