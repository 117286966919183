import { getWalletClient, readContract } from '@wagmi/core';

import { fundAbi, orgFundFactoryAbi, transferDeployerAbi } from '@endaoment-frontend/abis';
import { GetFund, GetOrg } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { ensureUserChain, getContractAddressForChain, getOrgDeploymentForChain } from '@endaoment-frontend/multichain';
import type { Address, CreateGrantInput, DonationRecipient, UUID } from '@endaoment-frontend/types';
import { equalAddress, getHexForOrgDeployment } from '@endaoment-frontend/utils';

import type { ReactionHookOptions } from './generateReactionHook';
import { useGenerateReactionHook } from './generateReactionHook';
import { resolveOrgDeployment } from './resolveOrgDeployment';
import { writeContractWithIncreasedGas } from './writeContractWithIncreasedGas';

type CreateGrantArgs = {
  fundId: UUID;
  destination: DonationRecipient;
  grantAmount: bigint;
  input: CreateGrantInput;
};

const getContractAddressForDonationDestination = async (
  destination: DonationRecipient,
  chainId: number,
): Promise<Address | undefined> => {
  if (destination.type === 'fund') {
    const destinationFund = await GetFund.fetchFromDefaultClient([destination.id]);
    return destinationFund.v2ContractAddress ?? undefined;
  }

  if (destination.type === 'org') {
    const destinationOrg = await GetOrg.fetchFromDefaultClient([destination.einOrId]);
    return getOrgDeploymentForChain(destinationOrg.deployments, chainId)?.contractAddress ?? undefined;
  }

  throw new Error('Invalid donation destination');
};

export const createGrantTransaction: ReactionHookOptions<CreateGrantArgs>['createTransaction'] = async ({
  args,
  wagmiConfig,
}) => {
  const walletClient = await getWalletClient(wagmiConfig);
  const chainId = walletClient.chain.id;
  const { fundId, destination, grantAmount } = args;

  const fund = await GetFund.fetchFromDefaultClient([fundId]);
  const offAddress = getContractAddressForChain(chainId, 'orgFundFactory');
  const transferDeployerAddress = getContractAddressForChain(chainId, 'transferDeployer');

  // Ensure that the user is on the same chain as the fund
  await ensureUserChain(wagmiConfig, fund.chainId);

  // We do not currently support grants originating from an undeployed fund
  if (typeof fund.v2ContractAddress !== 'string') {
    throw new Error('Attempting to create a grant with a fund that does not have a v2 contract address');
  }

  if (destination.type === 'fund') {
    const destinationFund = await GetFund.fetchFromDefaultClient([destination.id]);
    if (destinationFund.chainId !== fund.chainId) throw new Error('Cannot transfer to a fund on a different chain');

    // Deploy and transfer to an undeployed fund
    if (!destinationFund.v2ContractAddress) {
      // Need to get the expected salt from FundDetails
      if (!destinationFund.expectedDeploymentInfo)
        throw new Error('FundDetails does not have expectedDeploymentInfo even though fund is not deployed');

      // Validate that the computed contract address matches the expected contract address
      const computedContractAddress = await readContract(wagmiConfig, {
        abi: orgFundFactoryAbi,
        functionName: 'computeFundAddress',
        address: offAddress,
        args: [
          destinationFund.expectedDeploymentInfo.expectedManagerAddress,
          destinationFund.expectedDeploymentInfo.expectedSalt,
        ],
      });

      if (!equalAddress(computedContractAddress, destinationFund.expectedDeploymentInfo.expectedComputedAddress)) {
        throw new Error(
          `Fund Deployment failed, computed contract address ${computedContractAddress} does not match expected contract address ${destinationFund.expectedDeploymentInfo.expectedComputedAddress}`,
        );
      }

      return writeContractWithIncreasedGas(wagmiConfig, {
        abi: transferDeployerAbi,
        functionName: 'deployFundAndTransfer',
        address: transferDeployerAddress,
        args: [
          fund.v2ContractAddress,
          destinationFund.expectedDeploymentInfo.expectedManagerAddress,
          destinationFund.expectedDeploymentInfo.expectedSalt,
          grantAmount,
        ],
        account: walletClient.account,
      });
    }
  }

  if (destination.type === 'org') {
    const destinationOrg = await GetOrg.fetchFromDefaultClient([destination.einOrId]);
    // Verify if the org is deployed & registered
    // This guarantees the org deployment info will exist, even if the org's deployment wasn't properly registered with the BE
    const orgDeployment = await resolveOrgDeployment(destinationOrg, wagmiConfig);

    // If the org is truly not deployed, we need to deploy it and transfer the grant
    if (!orgDeployment) {
      // Ensure that we are using the EIN if it exists
      // DO NOT use the field from the destination argument as the source of truth since it may accidentally be the org's ID
      const encodedDeployHex = getHexForOrgDeployment(destinationOrg.ein ?? destinationOrg.id);
      return writeContractWithIncreasedGas(wagmiConfig, {
        abi: transferDeployerAbi,
        functionName: 'deployOrgAndTransfer',
        address: transferDeployerAddress,
        args: [fund.v2ContractAddress, encodedDeployHex, grantAmount],
        account: walletClient.account,
      });
    }
  }

  // Happy path of transferring to a deployed org or fund from a deployed fund
  const destinationAddress = await getContractAddressForDonationDestination(destination, chainId);
  if (!destinationAddress) throw new Error('Attempted to directly transfer to an invalid destination');

  return writeContractWithIncreasedGas(wagmiConfig, {
    abi: fundAbi,
    functionName: 'transferToEntity',
    address: fund.v2ContractAddress,
    args: [destinationAddress, grantAmount],
    account: walletClient.account,
  });
};

export const useCreateGrant = () => {
  return useGenerateReactionHook<CreateGrantArgs>({
    actionName: 'CREATE_GRANT',
    createTransaction: createGrantTransaction,
    createDescription: () => 'Granting',
    createExtra: ({ args }) => args.input,
    confirmations: defaults.confirmations.transfer,
  });
};
