import type { As } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';

import { GetFund } from '@endaoment-frontend/api';
import { useAuthType } from '@endaoment-frontend/authentication';
import type { FundListing, PolymorphicProps, UUID } from '@endaoment-frontend/types';
import { ChainIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { formatCurrency, formatUsdc, getFundTotalValues, stringToColor } from '@endaoment-frontend/utils';

import { MiniLoadingDetails, componentMatch } from './MiniEntityDetails';
import styles from './MiniEntityDetails.module.scss';

type MiniFundDetailsProps<Tag extends As> = PolymorphicProps<
  'div',
  Tag,
  {
    fund: Pick<
      FundListing,
      | 'chainId'
      | 'inTransitBuyUsdcAmount'
      | 'inTransitSellUsdcAmount'
      | 'investedUsdc'
      | 'lifetimeDonationsUsdc'
      | 'logo'
      | 'name'
      | 'totalGrantedUsdc'
      | 'type'
      | 'usdcBalance'
    >;
    balance?: string;
    showBalance?: boolean;
    padding?: boolean;
    extraDetails?: boolean;
  }
>;

export const MiniFundDetails = <Tag extends As>({
  fund,
  balance,
  className,
  padding = true,
  extraDetails = false,
  showBalance = false,
  children,
  ...props
}: MiniFundDetailsProps<Tag>) => {
  const { isWalletAuth } = useAuthType();
  return (
    <Box
      className={clsx(
        styles['entity-details'],
        styles['entity-details--fund'],
        !padding && styles['entity-details--nopad'],
        className,
      )}
      as={componentMatch(props)}
      {...props}>
      <div className={styles['entity-details__logo']}>
        {fund.logo ? (
          <Image src={fund.logo} alt='' height={100} width={100} />
        ) : (
          <StarIcon
            startColor={stringToColor(fund.name)}
            endColor={stringToColor(fund.name.split('').reverse().join())}
          />
        )}
        {!!isWalletAuth && <ChainIcon chainId={fund.chainId} className={styles['chain-icon']} filled light />}
      </div>
      <h6>
        <div>{fund.name}</div>
        {/* TODO: Support full text search */}
        <span>
          {extraDetails ? `${fund.type} Fund` : balance ?? `${formatCurrency(formatUsdc(fund.usdcBalance))} Grantable`}
        </span>
      </h6>
      {!!extraDetails && showBalance ? (
        <div className={styles['entity-details__extra']}>
          <div className={styles['number-display']}>
            <b>{formatCurrency(formatUsdc(fund.usdcBalance), { compact: true, lowercase: true })}</b>
            Grantable
          </div>
        </div>
      ) : (
        <div className={styles['entity-details__extra']}>
          <div className={styles['number-display']}>
            <b>
              {formatCurrency(formatUsdc(getFundTotalValues(fund).fundBalance), { compact: true, lowercase: true })}
            </b>
            Total Balance
          </div>
        </div>
      )}
      {children}
    </Box>
  );
};

export const MiniFundDetailsWithQuery = <Tag extends As>({
  fundId,
  ...props
}: Omit<MiniFundDetailsProps<Tag>, 'fund'> & { fundId: UUID }) => {
  const { data: fund } = GetFund.useQuery([fundId]);

  if (!fund) return <MiniLoadingDetails />;

  return <MiniFundDetails fund={fund} {...props} />;
};
