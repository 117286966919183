import { match, P } from 'ts-pattern';
import { z } from 'zod';

import { useWalletModal } from '@endaoment-frontend/authentication';
import { StepModal } from '@endaoment-frontend/ui/shared';

import { LoginGate } from './common/LoginGate';
import { CreateFundFlow } from './create-fund-flow/CreateFundFlow';
import styles from './FundWizard.module.scss';
import { MigrateFundFlow } from './migrate-fund-flow/MigrateFundFlow';
import { useFundWizardState } from './useFundWizardState';
export const fundWizardModes = z.enum(['create', 'migrate']);

export const FundWizard = () => {
  const { isWalletModalOpen } = useWalletModal();

  const { isFundWizardOpen, state, closeFundWizard } = useFundWizardState();
  const onClose = () => {
    if (isWalletModalOpen) return;
    closeFundWizard();
  };

  const steps = match({ isOpen: isFundWizardOpen, state })
    .with(P.union({ isOpen: false }, { isOpen: true, state: { mode: undefined } }), () => <></>)
    .with({ isOpen: true, state: { mode: 'create' } }, ({ state }) => (
      <CreateFundFlow onClose={onClose} state={state} />
    ))
    .with({ isOpen: true, state: { mode: 'migrate' } }, ({ state }) => (
      <LoginGate>
        <MigrateFundFlow state={state} onClose={onClose} />
      </LoginGate>
    ))
    .exhaustive();

  return (
    <StepModal isOpen={isFundWizardOpen} onClose={onClose} className={styles['fund-wizard-modal']}>
      {steps}
    </StepModal>
  );
};
