import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { P, match } from 'ts-pattern';

import { GetFund } from '@endaoment-frontend/api';
import type { GrantInstructionInput, UUID } from '@endaoment-frontend/types';
import { grantInstructionInputSchema } from '@endaoment-frontend/types';
import {
  FormCheckbox,
  FormInput,
  FormTextArea,
  ProceedButton,
  Select,
  validateWithZod,
} from '@endaoment-frontend/ui/forms';
import { Loader } from '@endaoment-frontend/ui/shared';

import styles from '../DonationWizard.module.scss';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

export const GrantInstructionsStep = ({
  initialValues = {
    purpose: '',
    recommender: '',
    specialInstructions: '',
    shareMyEmail: true,
  },
  onSubmit,
  originFundId,
}: {
  initialValues?: Omit<GrantInstructionInput, 'recommendationId'>;
  onSubmit: (instructions: Omit<GrantInstructionInput, 'recommendationId'>) => void;
  originFundId: UUID;
}) => {
  const { data: fund, isPending: isPendingFund } = GetFund.useQuery([originFundId], { enabled: !!originFundId });
  const manager = fund?.advisor;
  const isCollaborator = useIsFundCollaborator(originFundId);

  const userName = isPendingFund ? '' : `${manager?.firstName} ${manager?.lastName}`;
  const OTHER_OPTION = 'other';

  const recommenderOptions = [
    { display: userName, value: userName },
    { display: 'Anonymous', value: 'Anonymous' },
    { display: 'Other', value: OTHER_OPTION },
  ];

  const purposeOptions = [
    { display: 'General Purpose', value: 'General Purpose' },
    { display: 'Restricted Grant', value: OTHER_OPTION },
  ];

  const [recommenderOption, setRecommenderOption] = useState(
    match(initialValues.recommender)
      .with(P.union('', recommenderOptions[0].value), () => recommenderOptions[0].value)
      .with(recommenderOptions[1].value, () => recommenderOptions[1].value)
      .otherwise(() => OTHER_OPTION),
  );
  const [purposeOption, setPurposeOption] = useState(
    initialValues.purpose === '' || initialValues.purpose === purposeOptions[0].value
      ? purposeOptions[0].value
      : OTHER_OPTION,
  );

  if (isPendingFund) return <Loader size='l' />;

  return (
    <Formik
      onSubmit={v => {
        const specialInstructions = purposeOption === OTHER_OPTION ? v.purpose : undefined;
        onSubmit({ ...v, specialInstructions, purpose: specialInstructions ? 'Restricted Grant' : v.purpose });
      }}
      initialValues={{
        ...initialValues,
        recommender: initialValues.recommender || userName,
        purpose:
          purposeOption === OTHER_OPTION && initialValues.specialInstructions
            ? initialValues.specialInstructions
            : initialValues.purpose || purposeOption,
      }}
      validate={validateWithZod(grantInstructionInputSchema)}>
      {({ setFieldValue }) => (
        <Form className={styles['step-form']}>
          <h2 className={styles['step-header']}>Provide Grant Details</h2>
          <div className={clsx(styles.group, styles['group--active'])}>
            <p className={styles.label}>Who is recommending this grant?*</p>
            <Select
              options={recommenderOptions}
              onChange={e => {
                const value = e.currentTarget.value;
                setRecommenderOption(value);
                setFieldValue('recommender', value !== OTHER_OPTION ? value : '');
              }}
              value={recommenderOption}
            />
            {recommenderOption === OTHER_OPTION && (
              <FormInput name='recommender' className={styles['grant-instructions']} placeholder='Recommender' />
            )}

            <p className={styles.label}>What is the purpose of this grant?*</p>
            <Select
              options={purposeOptions}
              onChange={e => {
                const value = e.currentTarget.value;
                setPurposeOption(value);
                setFieldValue('purpose', value !== OTHER_OPTION ? value : '');
              }}
              value={purposeOption}
            />
            {purposeOption === OTHER_OPTION && (
              <>
                <FormTextArea
                  name='purpose'
                  rows={2}
                  className={styles['grant-instructions']}
                  placeholder='Please describe the grant restriction'
                />
                <p>This organization will need to confirm the grant restriction before we begin the payout</p>
              </>
            )}
            <Box pl='1rem'>
              <FormCheckbox
                name='shareMyEmail'
                label={`Share ${isCollaborator ? 'collaborator ' : ''}email with the organization`}
                className={styles['update-checkbox']}
              />
            </Box>
          </div>
          <ProceedButton type='submit' />
        </Form>
      )}
    </Formik>
  );
};
