import { addBreadcrumb, captureException, startSpan } from '@sentry/nextjs';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { GetFundActivity, GetOrgActivity, GetUserActivity } from '@endaoment-frontend/activities';
import {
  GetAccurateFundPosition,
  GetFund,
  GetFundGrants,
  GetFundPositions,
  GetOrg,
  GetPortfolio,
  GetRecommendationById,
  GetRecommendationsForFund,
  GetRecommendationsMadeForMe,
  GetSubproject,
  GetUserFunds,
  GetUserPositions,
  RegisterDonation,
  RegisterGrant,
  RegisterOrgDeployment,
  RegisterTrade,
} from '@endaoment-frontend/api';
import type { StoredTransaction, TransactionActionKey } from '@endaoment-frontend/blockchain-interactions';
import {
  TransactionListUpdatedEvent,
  useManageTransactionList,
  useTransactionToast,
} from '@endaoment-frontend/blockchain-interactions';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import { CalculateEntityRebalance, RegisterRebalance } from '@endaoment-frontend/target-allocations';
import { createDonationInputSchema, createGrantInputSchema, uuidSchema } from '@endaoment-frontend/types';

type SentrySpan = Parameters<Parameters<typeof startSpan>[1]>[0];
type ReactionArgs = {
  transaction: StoredTransaction;
  span: SentrySpan;
};
type ReactionFn = UseMutateAsyncFunction<void, unknown, ReactionArgs>;

const useDeployOrgReaction = (): ReactionFn => {
  const queryClient = useQueryClient();
  const { addToast } = useTransactionToast();
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: async ({ transaction }: ReactionArgs) => {
      const res = await RegisterOrgDeployment.executeAndSave([transaction.hash, transaction.chainId]);

      for (const org of res) {
        GetOrg.invalidateQuery(queryClient, [org.id]);
        if (org.ein) GetOrg.invalidateQuery(queryClient, [org.ein]);

        addToast({
          type: 'success',
          title: 'Organization Deployed',
          link: { href: routes.app.org({ einOrId: org.id }), label: 'View Org' },
        });
      }

      removeTransaction(transaction.hash);
    },
    mutationKey: [RegisterOrgDeployment.key],
  });
  return mutateAsync;
};
const useDeployFundReaction = (): ReactionFn => {
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: ({ transaction }: ReactionArgs) => {
      // We handle all UI in the fund creation flow so this reaction is minimal
      removeTransaction(transaction.hash);
      return Promise.resolve();
    },
    mutationKey: [RegisterOrgDeployment.key],
  });
  return mutateAsync;
};
const useCreateDonationReaction = (): ReactionFn => {
  const queryClient = useQueryClient();
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: async ({ transaction }: ReactionArgs) => {
      const extra = createDonationInputSchema.parse(transaction.extra);

      try {
        const donation = await RegisterDonation.executeAndSave([transaction.hash, transaction.chainId, extra]);
        GetUserActivity.invalidateQuery(queryClient);

        await match(donation.entityType)
          .returnType<Promise<void>>()
          .with('fund', async () => {
            const fund = await GetFund.executeAndSave([donation.destinationEntity]);
            GetUserFunds.invalidateQuery(queryClient, []);
            GetFundActivity.invalidateQuery(queryClient, [donation.destinationEntity]);
            CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', donation.destinationEntity, true]);
            CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', donation.destinationEntity, false]);
            if (fund.vanityUrl) GetFund.setData(queryClient, [fund.vanityUrl], fund);
            if (extra.recommendationId) {
              GetRecommendationById.invalidateQuery(queryClient, [extra.recommendationId]);
              GetRecommendationsMadeForMe.invalidateQuery(queryClient);
              GetRecommendationsForFund.invalidateQuery(queryClient, [donation.destinationEntity]);
            }
          })
          .with('subproject', async () => {
            await GetSubproject.executeAndSave([donation.destinationEntity]);
          })
          .with('org', async () => {
            const org = await GetOrg.executeAndSave([donation.destinationEntity]);
            if (org.ein) GetOrg.setData(queryClient, [org.ein], org);
            GetOrgActivity.invalidateQuery(queryClient, [org.id]);
          })
          .otherwise(() => {
            console.warn("Donation didn't have a destination entity");
            return Promise.resolve();
          });
      } catch (e) {
        // If the request is bad, we want to remove the transaction from the queue
        if (
          isFetchError<{ error: string; message: string }>(e) &&
          e.statusCode === 400 &&
          e.data?.error === 'Bad Request'
        ) {
          captureException(e, {
            tags: {
              'transactions': transaction.type.toLowerCase().replace(/ |_/, '-'),
              'transaction-failure': false,
            },
            level: 'error',
            extra: { transaction, extra, error: e },
          });
          removeTransaction(transaction.hash);
          return;
        }
        throw e;
      }

      removeTransaction(transaction.hash);
    },
    mutationKey: [RegisterDonation.key],
  });
  return mutateAsync;
};
const useCreateGrantReaction = (): ReactionFn => {
  const queryClient = useQueryClient();
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: async ({ transaction }: ReactionArgs) => {
      const input = createGrantInputSchema.parse(transaction.extra);

      await RegisterGrant.executeAndSave([transaction.hash, transaction.chainId, input]);

      GetUserFunds.invalidateQuery(queryClient);
      GetFund.invalidateQuery(queryClient);
      GetFundGrants.invalidateQuery(queryClient);
      GetFundActivity.invalidateQuery(queryClient);
      GetOrgActivity.invalidateQuery(queryClient);
      GetUserActivity.invalidateQuery(queryClient);

      if (input.subprojectId) {
        GetSubproject.invalidateQuery(queryClient, [input.subprojectId]);
      }

      if (input.recommendationId) {
        GetRecommendationById.invalidateQuery(queryClient, [input.recommendationId]);
        GetRecommendationsMadeForMe.invalidateQuery(queryClient);
        GetRecommendationsForFund.invalidateQuery(queryClient);
      }

      removeTransaction(transaction.hash);
    },
    mutationKey: [RegisterGrant.key],
  });
  return mutateAsync;
};
const useEditPositionReaction = (): ReactionFn => {
  const queryClient = useQueryClient();
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: async ({ transaction }: ReactionArgs) => {
      const { recommendationId } = z
        .object({
          recommendationId: uuidSchema.nullish(),
        })
        .parse(transaction.extra);

      const registeredTrade = await RegisterTrade.executeAndSave([
        transaction.hash,
        transaction.chainId,
        false,
        recommendationId ?? undefined,
      ]);
      const { portfolioId, issuerEntity } = registeredTrade;

      GetPortfolio.invalidateQuery(queryClient, [portfolioId]);
      GetFund.invalidateQuery(queryClient, [issuerEntity]);
      GetFundPositions.invalidateQuery(queryClient, [issuerEntity, 'fast']);
      GetFundPositions.invalidateQuery(queryClient, [issuerEntity, 'accurate']);
      GetAccurateFundPosition.invalidateQuery(queryClient, [issuerEntity, portfolioId]);
      GetFundActivity.invalidateQuery(queryClient, [issuerEntity]);
      // There are two usages of this query, one for a specific portfolio, and one for all portfolios associated with a user
      GetUserPositions.invalidateQuery(queryClient, []);
      GetUserPositions.invalidateQuery(queryClient, [portfolioId]);
      GetUserActivity.invalidateQuery(queryClient);

      CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', issuerEntity, true]);
      CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', issuerEntity, false]);

      if (recommendationId) {
        GetRecommendationById.invalidateQuery(queryClient, [recommendationId]);
        GetRecommendationsMadeForMe.invalidateQuery(queryClient);
        GetRecommendationsForFund.invalidateQuery(queryClient, [issuerEntity]);
      }

      removeTransaction(transaction.hash);
    },
    mutationKey: [RegisterTrade.key],
  });
  return mutateAsync;
};
const useBatchDeployOrgsReaction = (): ReactionFn => {
  const queryClient = useQueryClient();
  const { addToast } = useTransactionToast();
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: async ({ transaction }: ReactionArgs) => {
      const { ids } = z.object({ ids: z.array(z.string()) }).parse(transaction.extra);

      const res = await RegisterOrgDeployment.executeAndSave([transaction.hash, transaction.chainId]);

      for (const org of res) {
        GetOrg.setData(queryClient, [org.id], org);
        if (org.ein) GetOrg.setData(queryClient, [org.ein], org);
      }

      if (res.length < ids.length) {
        addToast({
          type: 'success',
          title: 'Batch Org Deploy',
          blurbOverride: `Partial Success, Failed to deploy ${ids.length - res.length} organization${
            ids.length - res.length > 1 ? 's' : ''
          }`,
        });
        removeTransaction(transaction.hash);
      }

      addToast({
        type: 'success',
        title: 'Batch Org Deploy',
        // transactionHash: transaction.hash,
        blurbOverride: `Successfully batch deployed ${res.length} organization${res.length > 1 ? 's' : ''}`,
        link: { label: 'View Transaction', href: getTransactionLink(transaction.hash, transaction.chainId) },
      });
      removeTransaction(transaction.hash);
    },
    mutationKey: [RegisterOrgDeployment.key],
  });
  return mutateAsync;
};
const useRebalanceFundReaction = (): ReactionFn => {
  const queryClient = useQueryClient();
  const { removeTransaction } = useManageTransactionList();
  const { mutateAsync } = useMutation({
    mutationFn: async ({ transaction }: ReactionArgs) => {
      const registeredRebalance = await RegisterRebalance.executeAndSave([transaction.hash, transaction.chainId]);

      registeredRebalance.registeredTrades.forEach(({ portfolioId, issuerEntity }) => {
        GetPortfolio.invalidateQuery(queryClient, [portfolioId]);
        GetFund.invalidateQuery(queryClient, [issuerEntity]);
        GetFundPositions.invalidateQuery(queryClient, [issuerEntity, 'fast']);
        GetFundPositions.invalidateQuery(queryClient, [issuerEntity, 'accurate']);
        GetAccurateFundPosition.invalidateQuery(queryClient, [issuerEntity, portfolioId]);
        GetFundActivity.invalidateQuery(queryClient, [issuerEntity]);
        // There are two usages of this query, one for a specific portfolio, and one for all portfolios associated with a user
        GetUserPositions.invalidateQuery(queryClient, []);
        GetUserPositions.invalidateQuery(queryClient, [portfolioId]);
        GetUserActivity.invalidateQuery(queryClient);
        CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', issuerEntity, true]);
        CalculateEntityRebalance.invalidateQuery(queryClient, ['fund', issuerEntity, false]);
      });

      removeTransaction(transaction.hash);
    },
    mutationKey: [RegisterRebalance.key],
  });
  return mutateAsync;
};

export const Web3ReactionProvider = () => {
  const { addToast } = useTransactionToast();
  const { removeTransaction } = useManageTransactionList();

  // Pile all the mutations into a map for easy access
  const reactions: { [key in TransactionActionKey]: ReactionFn } = {
    DEPLOY_ORG: useDeployOrgReaction(),
    DEPLOY_FUND: useDeployFundReaction(),
    CREATE_DONATION: useCreateDonationReaction(),
    CREATE_GRANT: useCreateGrantReaction(),
    EDIT_POSITION: useEditPositionReaction(),
    BATCH_DEPLOY_ORG: useBatchDeployOrgsReaction(),
    REBALANCE_FUND: useRebalanceFundReaction(),
  };

  // Attach reactions as a listener to our transactions
  TransactionListUpdatedEvent.useEventListener(({ detail }) => {
    const transaction = detail.transaction;
    const { status, type, hash } = transaction;

    match(status)
      .returnType<void>()
      .with('pending', async () => {
        addBreadcrumb({
          level: 'log',
          type: 'transaction',
          category: 'transaction.presend',
          message: 'Attempting to handle callback before confirmation',
          data: { transaction },
        });
        await startSpan(
          {
            name: `${type.toLowerCase().replace(/ |_/g, '-')} (callback)`,
            op: 'blockchain-callback',
            attributes: {
              transactionHash: hash,
            },
          },
          span => reactions[type]({ transaction, span }),
        );
      })
      .with('success', async () => {
        addBreadcrumb({
          level: 'log',
          type: 'transaction',
          category: 'transaction.success',
          message: `Successfully received blockchain confirmation for ${type}`,
          data: { transaction },
        });

        await startSpan(
          {
            name: `${type.toLowerCase().replace(/ |_/g, '-')} (callback)`,
            op: 'blockchain-callback',
            attributes: {
              transactionHash: hash,
            },
          },
          span => reactions[type]({ transaction, span }),
        );
      })
      .with('error', () => {
        captureException(new Error(`Transaction failed for ${type}`), {
          tags: {
            'transactions': type.toLowerCase().replace(/ |_/, '-'),
            'transaction-failure': true,
          },
          level: 'warning',
          extra: { transaction },
        });

        // TODO: Add error handling on a per type basis

        addToast({ type: 'error', title: 'Transaction Failed' });
        removeTransaction(hash);
      })
      .exhaustive();
  });

  return <></>;
};
