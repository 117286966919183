import { trackEvent } from '@phntms/next-gtm';
import type { SetValues, Values } from 'nuqs';
import { useQueryStates } from 'nuqs';
import { useMemo } from 'react';

import { useIsMounted } from '@endaoment-frontend/hooks';
import { donationWizardParsers } from '@endaoment-frontend/routes';
import type { DonationRecipient, UUID } from '@endaoment-frontend/types';

type QueryStateValues = Values<typeof donationWizardParsers>;
export type OpenWizardOptions = {
  initialMode?: NonNullable<QueryStateValues['dwMode']>;
  initialRecipient?: DonationRecipient;
  initialOriginId?: UUID;
};

export const useDonationWizardState = () => {
  const [queryState, setQueryState] = useQueryStates(donationWizardParsers);
  const isMounted = useIsMounted();

  // Clean the query state to only acceptable values
  const state = useMemo(
    () => ({
      mode: queryState.dwMode ?? undefined,
      recipient: queryState.dwRecipient ?? undefined,
      amount: queryState.dwAmount,
      includeTaxReceipt: queryState.dwIncludeTaxReceipt,
      token: queryState.ercToken ?? undefined,
      tokenId: queryState.otcTokenId ?? undefined,
      originId: queryState.grantOriginId ?? undefined,
      grantInstructions: queryState.grantInstructions ?? undefined,
      ticker: queryState.brokerageTicker ?? undefined,
      shares: queryState.brokerageShares,
      lots: queryState.brokerageLots,
      brokerageInfo: queryState.brokerageBroker,
      pledgeAmount: queryState.creditPledgeAmount,
      otcDonationTransactionHash: queryState.otcDonationTransactionHash,
      // Rebalance is only applicable to fund donations, so we default to false for other types
      isRebalanceRequested: queryState.dwRecipient?.type === 'fund' ? queryState.isRebalanceRequested : false,
      recommendationId: queryState.recommendationId ?? undefined,
      requestScheduledLiquidation: queryState.requestScheduledLiquidation ?? false,
    }),
    [queryState],
  );
  const setters = useMemo(() => makeDonationWizardSetters(setQueryState), [setQueryState]);

  const openDonationWizard = (options?: OpenWizardOptions) => {
    void setQueryState({
      isDonationWizardOpen: true,
      dwMode: options?.initialMode ?? null,
      dwRecipient: options?.initialRecipient ?? null,
      grantOriginId: options?.initialOriginId ?? null,
    });

    trackEvent({
      event: 'dw_open_wizard',
      data: {
        dw_intent: options?.initialMode,
      },
    });
  };
  const resetDonationWizard = (resetToTypeSelect = false) => {
    void setQueryState(p => ({
      isDonationWizardOpen: resetToTypeSelect ? true : null,
      dwMode: null,
      dwRecipient: resetToTypeSelect ? p.dwRecipient : null,
      grantOriginId: null,
      grantInstructions: null,
      dwAmount: null,
      brokerageShares: null,
      brokerageTicker: null,
      brokerageLots: null,
      dwIncludeTaxReceipt: null,
      creditPledgeAmount: null,
      ercToken: null,
      otcTokenId: null,
      isRebalanceRequested: null,
      brokerageBroker: null,
      recommendationId: null,
      requestScheduledLiquidation: null,
      otcDonationTransactionHash: null,
    }));
  };

  return {
    isDonationWizardOpen: isMounted && queryState.isDonationWizardOpen,
    state,
    openDonationWizard,
    resetDonationWizard,
    /** This is for internal use, do not access outside the donation-wizard lib */
    setters,
  } as const;
};

const makeDonationWizardSetters = (setQueryState: SetValues<typeof donationWizardParsers>) => {
  const makeDWSetterWithUndefined =
    <T extends keyof QueryStateValues>(key: T) =>
    (newVal?: NonNullable<QueryStateValues[T]>) => {
      void setQueryState({ [key]: typeof newVal === 'undefined' ? null : newVal });
    };
  return {
    setMode: makeDWSetterWithUndefined('dwMode'),
    setRecipient: makeDWSetterWithUndefined('dwRecipient'),
    setAmount: makeDWSetterWithUndefined('dwAmount'),
    setCreditPledgeAmount: makeDWSetterWithUndefined('creditPledgeAmount'),
    setIncludeTaxReceipt: makeDWSetterWithUndefined('dwIncludeTaxReceipt'),
    setErcToken: makeDWSetterWithUndefined('ercToken'),
    setOtcTokenId: makeDWSetterWithUndefined('otcTokenId'),
    setGrantOriginId: makeDWSetterWithUndefined('grantOriginId'),
    setGrantInstructions: makeDWSetterWithUndefined('grantInstructions'),
    setBrokerageTicker: makeDWSetterWithUndefined('brokerageTicker'),
    setBrokerageShares: makeDWSetterWithUndefined('brokerageShares'),
    setBrokerageLots: makeDWSetterWithUndefined('brokerageLots'),
    setBrokerageInfo: makeDWSetterWithUndefined('brokerageBroker'),
    setIsRebalanceRequested: makeDWSetterWithUndefined('isRebalanceRequested'),
    setRequestScheduledLiquidation: makeDWSetterWithUndefined('requestScheduledLiquidation'),
    setOtcDonationTransactionHash: makeDWSetterWithUndefined('otcDonationTransactionHash'),
  };
};

export const useOpenDonationWizard = () => useDonationWizardState().openDonationWizard;
