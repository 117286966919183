import { z } from 'zod';

import {
  addressSchema,
  bigIntSchema,
  entityTypeSchema,
  portfolioSchema,
  portfolioTradeTypeSchema,
  timestampSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

export const targetAllocationSchema = z.object({
  entityId: uuidSchema,
  entityType: entityTypeSchema,
  portfolioId: uuidSchema,
  percentage: z.number(),
});
export type TargetAllocation = z.infer<typeof targetAllocationSchema>;

export const targetAllocationRebalanceOperationSchema = z.object({
  portfolio: portfolioSchema,
  estimatedUsdcMoved: bigIntSchema,
  tradeType: portfolioTradeTypeSchema,
  calldata: addressSchema.nullish(),
  order: z.number(),
  readyForExecution: z.boolean(),
});
export type TargetAllocationRebalanceOperation = z.infer<typeof targetAllocationRebalanceOperationSchema>;

export const tradeRegistrationResponseSchema = z.object({
  id: uuidSchema,
  portfolioId: uuidSchema,
  issuerEntity: uuidSchema,
  entityType: entityTypeSchema,
  type: portfolioTradeTypeSchema,
  shares: z.string().nullish(),
  dateUtc: timestampSchema,
  /** A USDC value */
  amount: bigIntSchema,
  /** A USDC value */
  fee: bigIntSchema.nullish(),
  transactionHash: addressSchema,
  logIndex: z.number(),
  chainId: z.number(),
});
export type TradeRegistrationResponse = z.infer<typeof tradeRegistrationResponseSchema>;
