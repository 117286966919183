import { getPublicClient, getWalletClient, readContract } from '@wagmi/core';
import { getContractEvents } from 'viem/actions';
import type { Config } from 'wagmi';

import { orgFundFactoryAbi } from '@endaoment-frontend/abis';
import { GetOrg, RegisterOrgDeployment } from '@endaoment-frontend/api';
import { getContractAddressForChain, getOrgDeploymentForChain } from '@endaoment-frontend/multichain';
import type { Org, OrgDeployment } from '@endaoment-frontend/types';
import { equalAddress, getHexForOrgDeployment } from '@endaoment-frontend/utils';

// TODO: Add test coverage
export const resolveOrgDeployment = async (org: Org, wagmiConfig: Config): Promise<OrgDeployment | undefined> => {
  const walletClient = await getWalletClient(wagmiConfig);
  const chainId = walletClient.chain.id;
  const offAddress = getContractAddressForChain(chainId, 'orgFundFactory');

  // If already present, just return it
  const orgDeployment = getOrgDeploymentForChain(org.deployments, chainId);
  if (orgDeployment) return orgDeployment;

  // If we have no deployment information, the org might still be deployed but unregistered with the BE
  // To confirm, we first get the computed address for this org, based on its ein or id
  const encodedDeployHex = getHexForOrgDeployment(org.ein ?? org.id);
  const computedOrgAddress = await readContract(wagmiConfig, {
    abi: orgFundFactoryAbi,
    functionName: 'computeOrgAddress',
    address: offAddress,
    account: walletClient.account,
    args: [encodedDeployHex],
    chainId,
  });

  const publicClient = getPublicClient(wagmiConfig);
  if (!publicClient) throw new Error('Public client is not available');
  if (publicClient.chain.id !== chainId) throw new Error('Public client is not on the same chain as the wallet client');

  // Search for the org deployment event on the OFF
  const offDeployEventsForOrg = await getContractEvents(publicClient, {
    abi: orgFundFactoryAbi,
    address: offAddress,
    eventName: 'EntityDeployed',
    args: {
      entity: computedOrgAddress,
      entityType: 1,
    },
    fromBlock: 'earliest',
    toBlock: 'latest',
  });

  // If a deployment event is found by the filters, this means the org has been deployed but is unregistered on the BE
  const isDeployed = offDeployEventsForOrg.length > 0;

  if (isDeployed) {
    const deployTransactionHash = offDeployEventsForOrg[0].transactionHash;
    const newlyRegisteredOrgList = await RegisterOrgDeployment.executeAndSave([deployTransactionHash, chainId]);

    // Given a single transaction can contain multiple deployments, we need to invalidate the org queries for all deployments
    for (const org of newlyRegisteredOrgList) {
      GetOrg.invalidateDefaultClientQuery([org.id]);
      if (org.ein) GetOrg.invalidateDefaultClientQuery([org.ein]);
    }

    // Now search for the specific org deployment, matching by contract address
    const newlyRegisteredOrg = newlyRegisteredOrgList.find(org =>
      equalAddress(computedOrgAddress, getOrgDeploymentForChain(org.deployments, chainId)?.contractAddress),
    );
    // These errors cases should never happen, but we throw them just in case
    if (!newlyRegisteredOrg) throw new Error('Could not find newly registered org');
    const newlyRegisteredOrgDeployment = getOrgDeploymentForChain(newlyRegisteredOrg.deployments, chainId);
    if (!newlyRegisteredOrgDeployment?.contractAddress) throw new Error('Could not find newly registered org');

    return newlyRegisteredOrgDeployment;
  }

  // Org is truly undeployed
  return undefined;
};
