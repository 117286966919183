import { match } from 'ts-pattern';

import { GetSwapInfo } from '@endaoment-frontend/api';
import { useTransactionWithTimestamp } from '@endaoment-frontend/blockchain-interactions';
import { defaults } from '@endaoment-frontend/config';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import type { Address, DonationRecipient, EVMToken, TransactionStatus } from '@endaoment-frontend/types';
import { ShareIcon2 } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatDate, formatUsdc } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';
import { estimateProceeds, estimateTokenConversionRate, specialFormatTokenNumber } from '../helpers';

type ViewDonationProps = {
  destination: DonationRecipient;
  token: EVMToken;
  tokenAmount: bigint;
  donationStatus: TransactionStatus;
  transactionHash?: Address;
  transactionChainId?: number;
};

export const ViewDonation = ({
  destination,
  token,
  tokenAmount,
  donationStatus,
  transactionHash,
  transactionChainId,
}: ViewDonationProps) => {
  const { transactionReceipt, error, timestamp } = useTransactionWithTimestamp(
    transactionHash,
    transactionChainId,
    defaults.confirmations.donation,
  );

  const { data: swapInfo } = GetSwapInfo.useQuery(
    [tokenAmount, token, destination, transactionChainId ?? defaults.network.defaultChainId],
    {
      enabled: !!transactionChainId,
    },
  );

  if (!swapInfo) {
    return <Loader size='l' />;
  }

  return (
    <>
      <EntityCardWithLabel label='From' entity={{ type: 'user' }} />
      <EntityCardWithLabel label='Donating to' entity={destination} />
      <div className={styles['donation-info']}>
        <div>
          <h4>Donation</h4>
          <h4>
            {specialFormatTokenNumber(tokenAmount, token)}
            &nbsp;
            {token.symbol}
          </h4>
        </div>
        <div>
          <h4>Proceeds</h4>
          <h4>{formatCurrency(formatUsdc(estimateProceeds(token, swapInfo.quote).estimatedProceeds))}</h4>
        </div>
        <div>
          <h6>Price</h6>
          <h6>{`${estimateTokenConversionRate({ token, swapInfo })} ${token.symbol} / USDC`}</h6>
        </div>
        <div>
          <h6>Endaoment Fee</h6>
          <h6>{formatCurrency(formatUsdc(swapInfo.quote.endaomentFee))}</h6>
        </div>
      </div>
      <hr />
      <div className={styles['transaction-status-section']}>
        {match(donationStatus)
          .with(
            'success',
            () =>
              transactionReceipt && (
                <div>
                  Donation completed
                  {!!timestamp && (
                    <>
                      :&nbsp;
                      <span>{formatDate(timestamp, { includeTime: true, dateStyle: 'short' })}</span>
                    </>
                  )}
                </div>
              ),
          )
          .with('error', () => (
            <div>
              <b>Transaction failure</b>
              <p>{error?.message}</p>
            </div>
          ))
          .otherwise(() => (
            <div>
              <Loader size='s' />
              Donating...
            </div>
          ))}
        {!!transactionHash && !!transactionChainId && (
          <Button
            as='a'
            href={getTransactionLink(transactionHash, transactionChainId)}
            size='small'
            float={false}
            filled
            target='_blank'>
            View Transaction <ShareIcon2 color='#627EEA' />
          </Button>
        )}
      </div>
    </>
  );
};
