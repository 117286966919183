import { Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useAccountDisplayName, useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { useTransactionList } from '@endaoment-frontend/blockchain-interactions';
import { routes } from '@endaoment-frontend/routes';
import { SimpleSearch } from '@endaoment-frontend/search';
import { CloseIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import dashboardIcon from '../../images/navbar/dashboard.svg';
import exploreIcon from '../../images/navbar/explore.svg';
import portfoliosIcon from '../../images/navbar/portfolios.svg';

import { NavLink, WalletAuthText, computeIsWalletButtonSkeletonLoaded } from './CommonNavBarItems';
import styles from './NavBar.module.scss';

type MobileMenuProps = {
  onClose: () => void;
  onOpenUserProfile: () => void;
};

export const MobileMenu = ({ onClose, onOpenUserProfile }: MobileMenuProps) => {
  // Get which href is currently active
  const { asPath } = useRouter();

  const authState = useAuth();
  const { isSignedIn } = authState;
  const { accountName } = useAccountDisplayName();
  const { showWallet } = useWalletModal();

  const isWalletButtonSkeletonLoaded = computeIsWalletButtonSkeletonLoaded(authState, accountName);

  const pendingCount = useTransactionList().pendingTransactions.length;

  return (
    <motion.div
      initial='closed'
      animate='open'
      exit='closed'
      variants={{
        open: { visibility: 'visible', opacity: 1 },
        closed: { visibility: 'hidden', opacity: 0 },
      }}
      className={styles['mobile-menu']}>
      <div className={styles['top-bar']}>
        <Flex align='center' gap='1rem' px='0.5rem'>
          <Link href={routes.app.dashboard()} onClick={() => onClose()} title='Endaoment' id='logo'>
            <LogoIcon width={45} />
          </Link>
          <Button
            as='div'
            className={clsx(
              styles['wallet-status'],
              !isWalletButtonSkeletonLoaded || isSignedIn
                ? styles['wallet-status--connected']
                : styles['wallet-status--disconnected'],
            )}
            float={false}
            onClick={() => {
              onClose();
              isSignedIn ? onOpenUserProfile() : void showWallet();
            }}>
            <WalletAuthText isWalletButtonSkeletonLoaded={isWalletButtonSkeletonLoaded} />
          </Button>
        </Flex>
        <Button onClick={onClose} float={false} minimal className={styles['mobile-menu-close']}>
          <CloseIcon />
        </Button>
      </div>
      <div className={styles['search']}>
        {/* Set key to current path to reset component on route change */}
        <SimpleSearch key={asPath} className={styles['search-input']} compressed onSelect={onClose} />
      </div>
      {pendingCount > 0 && (
        <Link className={styles['pending-transactions']} href={routes.app.dashboard()} onClick={onClose}>
          <Loader />
          {pendingCount}
        </Link>
      )}
      <NavLink
        label='Dashboard'
        onClick={onClose}
        icon={<Image src={dashboardIcon} alt='' width={24} height={24} priority />}
        href={routes.app.dashboard()}
        current={asPath}
      />
      <NavLink
        label='Portfolios'
        onClick={onClose}
        icon={<Image src={portfoliosIcon} alt='' width={24} height={24} priority />}
        href={routes.app.portfolios()}
        current={asPath}
      />
      <NavLink
        label='Explore'
        onClick={onClose}
        icon={<Image src={exploreIcon} alt='' width={24} height={24} priority />}
        href={routes.app.explore()}
        current={asPath}
      />
      <hr />
      <div className={styles['org-links']}>
        <h3>For Orgs</h3>
        <Link href={routes.app.orgClaim()} onClick={onClose}>
          Claim Org
        </Link>
        <Link href='https://docs.endaoment.org/organizations' target='_blank'>
          Org Resources
        </Link>
      </div>
    </motion.div>
  );
};
