import { VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';

import { GetUserIdentity, UpdateUserIdentity } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { useGoogleLocation } from '@endaoment-frontend/hooks';
import type { DonationRecipient, PhysicalAddress } from '@endaoment-frontend/types';
import {
  CountryFormInput,
  FormCheckbox,
  FormInput,
  StateFormInput,
  ZipFormInput,
  validateWithZod,
} from '@endaoment-frontend/ui/forms';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import type { DonationTaxInfoFormData } from '../DonationWizard.types';
import { donationTaxInfoSchema } from '../DonationWizard.types';

import styles from './DonationTaxInfo.module.scss';

const DonationTaxInfoForm = ({
  donationDestinationType,
  onSkip,
}: {
  donationDestinationType: DonationRecipient['type'];
  onSkip: () => void;
}) => {
  const { setFieldValue } = useFormikContext<DonationTaxInfoFormData>();
  const { ref } = useGoogleLocation(place => setFieldValue('address', place));

  return (
    <>
      <Form className={styles['form']}>
        <FormInput name='email' label='Email' />
        <div className={styles['split-row']}>
          <FormInput name='firstName' label='First Name' />
          <FormInput name='lastName' label='Last Name' />
        </div>
        <div className={styles['split-row']}>
          <FormInput name='address.line1' label='Address Line 1' innerRef={ref} />
          <FormInput name='address.line2' label='Address Line 2' />
        </div>
        <CountryFormInput countryFieldName='address.country' stateFieldName='address.state' />
        <div className={styles['split-row']}>
          <ZipFormInput name='address.zip' countryFieldName='address.country' />
          <StateFormInput name='address.state' countryFieldName='address.country' />
        </div>
        <FormInput name='address.city' label='City' />
        <FormCheckbox
          name='updateProfile'
          label='Update my profile with this information'
          className={styles['update-checkbox']}
        />
        {donationDestinationType === 'org' && (
          <FormCheckbox
            name='shareMyEmail'
            label='Share advisor email with the organization'
            className={styles['update-checkbox']}
          />
        )}
        <VStack mt='1.5rem'>
          <Button type='submit'>Confirm</Button>
          <Button
            onClick={() => onSkip()}
            variation='purple'
            minimal
            size='small'
            className={styles['skip-receipt']}
            float={false}>
            Proceed without Receipt
          </Button>
        </VStack>
      </Form>
    </>
  );
};

export const DonationTaxInfo = ({
  donationDestinationType,
  onSubmit,
  initialValues,
  onSkip,
}: {
  donationDestinationType: DonationRecipient['type'];
  onSubmit: (t: DonationTaxInfoFormData) => void;
  initialValues?: DonationTaxInfoFormData;
  onSkip: () => void;
}) => {
  const queryClient = useQueryClient();
  const { isSignedIn } = useAuth();
  const { data: userInfo, isPending: isPendingIdentity } = GetUserIdentity.useQuery([], { enabled: isSignedIn });
  const { mutateAsync: updateUserInfo } = useMutation({
    mutationFn: UpdateUserIdentity.execute,
    mutationKey: [UpdateUserIdentity.key],
    onSuccess: () => {
      GetUserIdentity.invalidateQuery(queryClient, []);
    },
  });

  const handleSubmit = async (values: DonationTaxInfoFormData) => {
    if (values.updateProfile) updateUserInfo(values);
    onSubmit(values);
  };

  // Add a loading state so Formik doesn't render until we know if the user has an identity or not
  // Required to check `isSignedIn` since react query keeps `isPending` true until the query is enabled - https://github.com/TanStack/query/issues/3584
  if (isSignedIn && isPendingIdentity) return <Loader size='l' />;

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        updateProfile: false,
        shareMyEmail: false,
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
          country: 'USA',
        } as PhysicalAddress,
        ...userInfo,
        ...initialValues,
      }}
      validate={validateWithZod(donationTaxInfoSchema)}
      onSubmit={handleSubmit}>
      <DonationTaxInfoForm onSkip={onSkip} donationDestinationType={donationDestinationType} />
    </Formik>
  );
};
