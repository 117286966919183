import clsx from 'clsx';
import type { FormikErrors } from 'formik';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { z } from 'zod';

import { GetFund } from '@endaoment-frontend/api';
import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import type { DonationRecipient, FundDetails, UUID } from '@endaoment-frontend/types';
import { bigIntSchema } from '@endaoment-frontend/types';
import { BigNumberInput, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

type AmountFormValues = { amount: bigint };

const makeValidateAmountForm =
  (fund: FundDetails | undefined, isCollaborator: boolean | undefined) =>
  (v: AmountFormValues): FormikErrors<AmountFormValues> => {
    if (isCollaborator)
      return validateWithZod(
        z.object({
          amount: bigIntSchema
            .refine(v => v > 0n, 'Grant amount must be more than 0')
            .refine(
              () => typeof isCollaborator !== 'undefined',
              'Still loading collaborator status, please try again in a few seconds',
            ),
          // Do not check balance if user is an advisor
        }),
      )(v);

    return validateWithZod(
      z.object({
        amount: bigIntSchema
          .refine(v => v > 0n, 'Grant amount must be more than 0')
          .refine(() => !!fund, 'Still loading fund, please try again in a few seconds')
          .refine(
            v => !!fund && v <= fund.usdcBalance,
            () => ({
              message: `Insufficient balance`,
            }),
          ),
      }),
    )(v);
  };

export const GrantAmountStep = ({
  onSubmit,
  originFundId,
  destination,
  initialValues,
  onRemoveOrigin,
  onRemoveDestination,
}: {
  onSubmit: (amount: AmountFormValues['amount']) => void;
  originFundId: UUID;
  destination: DonationRecipient;
  initialValues: AmountFormValues;
  onRemoveOrigin?: () => void;
  onRemoveDestination?: () => void;
}) => {
  const { data: fund } = GetFund.useQuery([originFundId]);
  const isFundCollaborator = useIsFundCollaborator(originFundId);

  const memoizedValidateAmountForm = useMemo(
    () => makeValidateAmountForm(fund, isFundCollaborator),
    [fund, isFundCollaborator],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={v => onSubmit(v.amount)} validate={memoizedValidateAmountForm}>
      {({ errors, values, setFieldValue, setFieldTouched }) => (
        <Form className={styles['step-form']}>
          <EntityCardWithLabel
            label='Granting from'
            entity={{ type: 'fund', id: originFundId }}
            onRemove={onRemoveOrigin}
          />
          <EntityCardWithLabel label='Granting to' entity={destination} onRemove={onRemoveDestination} />
          <hr />
          <h2 className={styles['step-header']}>How much would you like to award?</h2>
          <div className={styles['donation-token-input']}>
            <div className={styles['donation-token-input__top']}>
              <div
                className={clsx(styles['donation-token-input__input'], styles['donation-token-input__input--grant'])}>
                <BigNumberInput
                  name='amount'
                  value={values.amount}
                  units={STABLECOIN_DECIMALS}
                  isDollars
                  onChange={v => setFieldValue('amount', v, true)}
                  onBlur={() => setFieldTouched('amount', true, true)}
                  data-testid='grant-amount-input'
                />
              </div>
            </div>
            <div className={clsx(styles['donation-token-input__details'])}>
              <div className={styles['error-container']}>
                {!!errors.amount && <motion.span>{`${errors.amount}`}</motion.span>}
              </div>
              {!isFundCollaborator && !!fund && (
                <span className={styles['user-balance']}>{`${formatCurrency(
                  formatUsdc(fund.usdcBalance),
                )} Available`}</span>
              )}
            </div>
          </div>
          <ProceedButton type='submit' className={styles['next-button--closer']} />
        </Form>
      )}
    </Formik>
  );
};
