import { Text } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import { useQueryClient } from '@tanstack/react-query';
import type { Ref } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { P, match } from 'ts-pattern';
import { useSwitchChain } from 'wagmi';

import { GetFundActivity, GetOrgActivity, GetUserActivity } from '@endaoment-frontend/activities';
import {
  CreateCryptoDonationPledge,
  CreateOtcRecommendation,
  GetOrg,
  GetRecommendationsForFund,
  GetRecommendationsMadeByMe,
  GetToken,
} from '@endaoment-frontend/api';
import { useIdempotencyKey } from '@endaoment-frontend/hooks';
import type { EVMToken } from '@endaoment-frontend/types';
import { StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel, useShowFireworks } from '@endaoment-frontend/ui/smart';

import { useInitializeTaxReceipt } from '../common/TaxReceiptButton';
import styles from '../DonationWizard.module.scss';
import type { DonationTaxInfoFormData, SubformProps } from '../DonationWizard.types';
import { DonationTaxInfo } from '../erc-donation-flow/DonationTaxInfo';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

import { OtcDonationView } from './OtcDonationView';
import { OtcTokenInput } from './OtcTokenInput';
import { OtcTransactionInput } from './OtcTransactionInput';

const otcDonationSteps = ['token-amount', 'tax-receipt', 'transaction-input', 'view'] as const;
const otcDonationPages = otcDonationSteps.length + 1;
type OtcDonationStep = (typeof otcDonationSteps)[number];

const useOtcDonationFlowState = ({ rawState, rawStateSetters }: Pick<SubformProps, 'rawState' | 'rawStateSetters'>) => {
  const [step, setStep] = useReducer((_prev: OtcDonationStep, next: OtcDonationStep) => {
    trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: rawState.mode } });
    return next;
  }, 'token-amount');
  const [taxInfo, setTaxInfo] = useState<DonationTaxInfoFormData>();

  return {
    wizard: {
      step,
      recipient: rawState.recipient,
      tokenId: rawState.tokenId,
      tokenAmount: rawState.amount,
      otcDonationTransactionHash: rawState.otcDonationTransactionHash,
      includeTaxReceipt: rawState.includeTaxReceipt,
      taxInfo,
      isRebalanceRequested: rawState.isRebalanceRequested,
      recommendationId: rawState.recommendationId,
      requestScheduledLiquidation: rawState.requestScheduledLiquidation,
    } as const,
    setStep,
    setTokenId: rawStateSetters.setOtcTokenId,
    setTokenAmount: rawStateSetters.setAmount,
    setIncludeTaxReceipt: rawStateSetters.setIncludeTaxReceipt,
    setTaxInfo,
    setIsRebalanceRequested: rawStateSetters.setIsRebalanceRequested,
    setRequestScheduledLiquidation: rawStateSetters.setRequestScheduledLiquidation,
    setOtcDonationTransactionHash: rawStateSetters.setOtcDonationTransactionHash,
  } as const;
};

const OtcDonationFlowWithRef = (
  { onClose, onReset, rawState, rawStateSetters }: SubformProps,
  ref?: Ref<HTMLDivElement>,
) => {
  const queryClient = useQueryClient();
  const idempotencyKey = useIdempotencyKey();
  const { switchChain } = useSwitchChain();
  const showFireworks = useShowFireworks();
  const {
    mutate: handleSubmit,
    status: pledgeStatus,
    data: pledgeId,
  } = CreateCryptoDonationPledge.useMutation({
    onSuccess: async (_data, [input]) => {
      GetUserActivity.invalidateQuery(queryClient, []);

      flowState.setStep('view');
      showFireworks();

      if (input.recipient.type === 'org') {
        const org = await GetOrg.fetchFromDefaultClient([input.recipient.einOrId]);
        GetOrgActivity.invalidateQuery(queryClient, [org.id]);
      }

      if (input.recipient.type === 'fund') {
        GetFundActivity.invalidateQuery(queryClient, [input.recipient.id]);
      }

      if (input.tokenId) {
        const token = await GetToken.fetchFromDefaultClient([input.tokenId]);
        trackEvent({
          event: 'dw_donation_pledge',
          data: {
            pledge_type: 'otc_crypto',
            destination_type: input.recipient.type,
            destination_id: input.recipient.type === 'fund' ? input.recipient.id : input.recipient.einOrId,
            token: token.name,
            amount: input.tokenAmount.toString(),
          },
        });
      }
    },
    retryDelay: 5000,
  });
  const { mutate: handleRecommend, status: recommendStatus } = CreateOtcRecommendation.useMutation({
    onSuccess: async (_data, [input]) => {
      GetRecommendationsForFund.invalidateQuery(queryClient, [input.collaboratingFundId]);
      GetRecommendationsMadeByMe.invalidateQuery(queryClient, []);

      const token = await GetToken.fetchFromDefaultClient([input.tokenId]);
      if (token) {
        trackEvent({
          event: 'dw_otc_recommendation',
          data: {
            token: token.symbol,
            amount: input.inputAmount.toString(),
            destination_type: 'fund',
            destination_id: input.collaboratingFundId,
          },
        });
      }

      setTimeout(() => onClose(), 2 * 1000);
    },
  });

  const flowState = useOtcDonationFlowState({
    rawState,
    rawStateSetters,
  });
  const [disclaimerAcknowledged, setDisclaimerAcknowledged] = useState(false);

  const isFundCollaborator = useIsFundCollaborator(
    flowState.wizard.recipient?.type === 'fund' ? flowState.wizard.recipient.id : undefined,
  );

  useInitializeTaxReceipt(
    userIdentity => {
      if (!userIdentity) {
        flowState.setIncludeTaxReceipt(false);
        flowState.setTaxInfo(undefined);
        return;
      }
      flowState.setIncludeTaxReceipt(true);
      flowState.setTaxInfo({
        ...userIdentity,
        shareMyEmail: true,
        updateProfile: false,
      });
    },
    { isCollaborator: !!isFundCollaborator },
  );

  const switchMethodToWallet = (token: EVMToken) => {
    // For orgs, always prompt to switch to token's chain id
    if (rawState.recipient?.type === 'org') {
      rawStateSetters.setErcToken(token);
      switchChain({ chainId: token.chainId });
    }
    // Since we don't have access to which chain the fund is on (currently), we don't prompt a chian switch
    // TODO: Add `chainId` to recipient to allow handling this
    // else if (rawState.recipient?.type === 'fund') {}

    rawStateSetters.setMode('erc-donation');
  };

  const steps = match(flowState.wizard)
    .with({ step: 'token-amount', recipient: P.nonNullable }, ({ tokenAmount, tokenId, recipient }) => {
      return (
        <StepModal.Step
          key='token-amount'
          ref={ref}
          onClose={onClose}
          onBack={onReset}
          header='New Donation'
          progress={{ current: 3, pages: otcDonationPages }}>
          <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={onReset} />
          <hr />
          <h2 className={styles['step-header']}>What will you be donating?</h2>
          <OtcTokenInput
            onSubmit={(token, amount) => {
              flowState.setTokenAmount(amount);
              flowState.setTokenId(token.id);
              flowState.setStep('transaction-input');
            }}
            onRecommend={(token, amount) => {
              flowState.setTokenAmount(amount);
              flowState.setTokenId(token.id);

              if (recipient.type !== 'fund' || !token) return;
              handleRecommend([
                {
                  collaboratingFundId: recipient.id,
                  inputAmount: amount,
                  tokenId: token.id,
                  uuid: idempotencyKey,
                },
              ]);
            }}
            recommendStatus={recommendStatus}
            switchMethodToWallet={switchMethodToWallet}
            recipient={recipient}
            initialValues={{
              amount: tokenAmount ?? 0n,
              tokenId,
            }}
          />
        </StepModal.Step>
      );
    })
    .with({ step: 'tax-receipt', recipient: P.nonNullable }, ({ taxInfo, recipient }) => (
      <StepModal.Step key='tax-receipt' ref={ref} onClose={onClose} header='New Donation / Tax Receipt'>
        {/* TODO: Move to shared component */}
        <DonationTaxInfo
          initialValues={taxInfo}
          donationDestinationType={recipient.type}
          onSkip={() => {
            flowState.setIncludeTaxReceipt(false);
            flowState.setStep('transaction-input');
          }}
          onSubmit={t => {
            flowState.setIncludeTaxReceipt(true);
            flowState.setTaxInfo(t);
            flowState.setStep('transaction-input');
          }}
        />
      </StepModal.Step>
    ))
    .with(
      {
        step: 'transaction-input',
        tokenId: P.nonNullable,
        tokenAmount: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({
        tokenId,
        tokenAmount,
        otcDonationTransactionHash,
        includeTaxReceipt,
        taxInfo,
        recipient,
        isRebalanceRequested,
        recommendationId,
        requestScheduledLiquidation,
      }) => (
        <StepModal.Step
          key='transaction-input'
          ref={ref}
          onClose={onClose}
          onBack={() => flowState.setStep('token-amount')}
          header='New Donation'
          progress={{ current: 5, pages: otcDonationPages }}>
          <EntityCardWithLabel label='Donating to' entity={recipient} />
          <OtcTransactionInput
            recipient={recipient}
            tokenId={tokenId}
            tokenAmount={tokenAmount}
            donorIdentity={
              includeTaxReceipt ? { taxReceipt: true, donorEmail: taxInfo?.email ?? '' } : { taxReceipt: false }
            }
            isProcessing={pledgeStatus === 'pending'}
            otcDonationTransactionHash={otcDonationTransactionHash}
            disclaimerAcknowledged={disclaimerAcknowledged}
            onDisclaimerAcknowledged={setDisclaimerAcknowledged}
            onSubmit={otcDonationTransactionHash => {
              flowState.setOtcDonationTransactionHash(otcDonationTransactionHash);
              handleSubmit([
                {
                  otcDonationTransactionHash,
                  tokenId,
                  tokenAmount,
                  recipient,
                  donorIdentity: taxInfo,
                  shareMyEmail: taxInfo?.shareMyEmail,
                  updateIdentity: taxInfo?.updateProfile,
                  isRebalanceRequested,
                  recommendationId,
                  requestScheduledLiquidation,
                },
              ]);
            }}
            isRebalanceRequested={isRebalanceRequested}
            onChangeRebalanceRequested={flowState.setIsRebalanceRequested}
            requestScheduledLiquidation={requestScheduledLiquidation}
            onChangeRequestScheduledLiquidation={flowState.setRequestScheduledLiquidation}
            onGoToTaxStep={otcDonationTransactionHash => {
              flowState.setOtcDonationTransactionHash(otcDonationTransactionHash);
              flowState.setStep('tax-receipt');
            }}
            onNoTaxReceipt={() => {
              flowState.setIncludeTaxReceipt(false);
              flowState.setTaxInfo(undefined);
            }}
          />
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'view',
        tokenId: P.nonNullable,
        tokenAmount: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({ tokenId, tokenAmount, recipient }) => (
        <StepModal.Step key='view' ref={ref} onClose={onClose} header='Donation'>
          <OtcDonationView
            recipient={recipient}
            tokenId={tokenId}
            tokenAmount={tokenAmount}
            donationPledgeId={pledgeId}
            onClose={onClose}
          />
        </StepModal.Step>
      ),
    )
    .otherwise(wizard => {
      console.error('Unhandled donation wizard state', wizard);
      return (
        <StepModal.Step key='error' ref={ref} onClose={onClose} header='Error'>
          <Text alignSelf='center'>Something went wrong</Text>
        </StepModal.Step>
      );
    });
  return steps;
};
export const OtcDonationFlow = forwardRef(OtcDonationFlowWithRef);
