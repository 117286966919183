import { match } from 'ts-pattern';
import { useChainId } from 'wagmi';

import { GetFund } from '@endaoment-frontend/api';
import { useTransactionWithTimestamp } from '@endaoment-frontend/blockchain-interactions';
import { defaults } from '@endaoment-frontend/config';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import type {
  Address,
  DonationRecipient,
  GrantInstructionInput,
  TransactionStatus,
  UUID,
} from '@endaoment-frontend/types';
import { ShareIcon2 } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatDate, formatUsdc } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';

import { useTransferFee } from './useTransferFee';

export const ViewGrant = ({
  originFundId,
  destination,
  grantInstructions,
  grantAmount = 0n,
  transactionHash,
  transactionChainId,
  grantStatus,
}: {
  originFundId: UUID;
  destination: DonationRecipient;
  grantInstructions?: Omit<GrantInstructionInput, 'shareMyEmail'>;
  grantStatus: TransactionStatus;
  grantAmount?: bigint;
  transactionHash?: Address;
  transactionChainId?: number;
}) => {
  const currentChainId = useChainId();
  const { data: fund } = GetFund.useQuery([originFundId], { enabled: !!originFundId });
  const { transactionReceipt, error, timestamp } = useTransactionWithTimestamp(
    transactionHash,
    transactionChainId,
    defaults.confirmations.transfer,
  );
  const { data: grantFeeToOrg, isPending: isPendingGrantFee } = useTransferFee({
    sender: fund?.v2ContractAddress,
    recipient: destination,
    chainId: fund?.chainId ?? currentChainId,
    transferAmount: grantAmount,
    enabled: !!fund,
  });

  const grantFee = destination.type === 'org' ? grantFeeToOrg : 0n;
  const estimatedProceeds = grantAmount - grantFee;

  return (
    <>
      <EntityCardWithLabel label='Granting from' entity={{ type: 'fund', id: originFundId }} />
      <EntityCardWithLabel label='Granting to' entity={destination} />
      <hr />
      <div className={styles['donation-info']}>
        {!isPendingGrantFee ? (
          <>
            <div>
              <h4>Grant</h4>
              <h4>{formatCurrency(formatUsdc(grantAmount))}</h4>
            </div>
            <div>
              <h4>{grantStatus !== 'success' && 'Estimated '}Proceeds</h4>
              <h4>{formatCurrency(formatUsdc(estimatedProceeds))}</h4>
            </div>
            <div>
              <h6>Endaoment Fee</h6>
              <h6>{formatCurrency(formatUsdc(grantFee))}</h6>
            </div>
          </>
        ) : (
          <Loader size='l' />
        )}
      </div>
      <hr />
      {!!grantInstructions && (
        <div className={styles['instruction-info']}>
          <label>Recommender</label>
          <p>{grantInstructions.recommender}</p>
          {grantInstructions.specialInstructions ? (
            <>
              <label>Restricted Grant Instructions</label>
              <p>{grantInstructions.specialInstructions}</p>
            </>
          ) : (
            <>
              <label>Grant Purpose</label>
              <p>{grantInstructions.purpose}</p>
            </>
          )}
        </div>
      )}
      <hr />
      <div className={styles['transaction-status-section']}>
        {match(grantStatus)
          .with(
            'success',
            () =>
              transactionReceipt && (
                <div>
                  Grant completed
                  {!!timestamp && (
                    <>
                      :&nbsp;
                      <span>{formatDate(timestamp, { includeTime: true, dateStyle: 'short' })}</span>
                    </>
                  )}
                </div>
              ),
          )
          .with('error', () => (
            <div>
              <b>Grant clawed back</b>
              <p>{error?.message}</p>
            </div>
          ))
          .otherwise(() => (
            <div>
              <Loader size='s' />
              Granting...
            </div>
          ))}
        {!!transactionHash && !!transactionChainId && (
          <Button
            as='a'
            href={getTransactionLink(transactionHash, transactionChainId)}
            size='small'
            float={false}
            filled
            target='_blank'>
            View Transaction <ShareIcon2 color='#627EEA' />
          </Button>
        )}
      </div>
    </>
  );
};
