import { addressSchema } from '@endaoment-frontend/types';
import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { EntityLabelPill, FundPill, MoneyPill } from '../common/ActivityPills';
import type { ActivitySubject, TransferActivity } from '../types';

import { ActivityDescription } from './ActivityDescription';

export const InternalTransferActivityDescription = ({
  activity,
  subject,
}: {
  activity: TransferActivity;
  subject?: ActivitySubject;
}) => {
  // If we are not rendering Org activity and the sender isn't a private fund
  const showSender = subject !== 'org' && !addressSchema.safeParse(activity.from.name).success;
  const grantOrTransferText = activity.type === 'grant' ? 'grant' : 'transfer';

  if (showSender) {
    return (
      <ActivityDescription>
        <EntityLabelPill entityLabel={activity.from} />
        <em>{`${grantOrTransferText}ed`}</em>
        <MoneyPill amount={formatCurrency(formatUsdc(activity.usdcAmount))} />
        <span>to</span>
        <EntityLabelPill entityLabel={activity.to} />
      </ActivityDescription>
    );
  }

  // If we are hiding the sender and we are showing org activity
  if (subject === 'org') {
    return (
      <ActivityDescription>
        <em>Received</em>
        <span>a</span>
        <MoneyPill amount={formatCurrency(formatUsdc(activity.usdcAmount))} />
        <em>{grantOrTransferText}</em>
        from
        <EntityLabelPill entityLabel={activity.from} />
      </ActivityDescription>
    );
  }

  // If we are simply hiding the sender
  return (
    <ActivityDescription>
      <EntityLabelPill entityLabel={activity.to} />
      <em>received</em>
      <span>a</span>
      <MoneyPill amount={formatCurrency(formatUsdc(activity.usdcAmount))} />
      <em>{grantOrTransferText}</em>
      from a
      <FundPill name='Private Fund' />
    </ActivityDescription>
  );
};
