import { useRouter } from 'next/router';
import { useState } from 'react';
import { match } from 'ts-pattern';

import { GetUserFunds } from '@endaoment-frontend/api';
import { useOpenFundWizard } from '@endaoment-frontend/fund-wizard';
import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { Input } from '@endaoment-frontend/ui/forms';
import { Button, Card, Loader } from '@endaoment-frontend/ui/shared';
import { MiniFundDetails } from '@endaoment-frontend/ui/smart';

import styles from '../DonationWizard.module.scss';

export const GrantOriginatingStep = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (fund: FundListing) => void;
}) => {
  const { data: userFunds } = GetUserFunds.useQuery([]);
  const [search, setSearch] = useState<string>('');
  const openFundWizard = useOpenFundWizard();
  const router = useRouter();

  const fundsWithGrantableBalance = (userFunds || []).filter(fund => fund.usdcBalance > 0n);
  // Filter by name
  const filteredFunds = fundsWithGrantableBalance.filter(fund =>
    fund.name.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <>
      <h2 className={styles['step-header']}>Which fund would you like to recommend a grant from?</h2>
      <Input
        className={styles['origin-funds-search']}
        placeholder='Filter Funds'
        value={search}
        onChange={e => setSearch(e.currentTarget.value)}
      />
      {!userFunds ? (
        <Loader size='l' className={styles['origin-funds-loader']} />
      ) : (
        <div className={styles['origin-funds']}>
          {match({
            userFundCount: userFunds.length,
            fundsWithGrantableBalanceCount: fundsWithGrantableBalance.length,
            filteredFunds,
          })
            .with({ userFundCount: 0 }, () => (
              <>
                Create a fund to start granting!
                <Button
                  onClick={() => {
                    onClose();
                    openFundWizard({ initialMode: 'create' });
                  }}
                  filled
                  float={false}
                  variation='fund'
                  className={styles['origin-funds__create-button']}>
                  Create a Fund
                </Button>
              </>
            ))
            .with({ fundsWithGrantableBalanceCount: 0 }, () => (
              <>
                <b className={styles['no-funds']}>
                  You have no funds with a grantable balance. Add assets into a fund to start granting!
                </b>
                <Button
                  onClick={() => {
                    onClose();
                    router.push(routes.app.dashboard());
                  }}
                  filled
                  float={false}
                  variation='fund'
                  className={styles['origin-funds__create-button']}>
                  Add Assets
                </Button>
              </>
            ))
            .otherwise(({ filteredFunds }) =>
              filteredFunds.map(fund => (
                <Card key={fund.id} noPadding>
                  <MiniFundDetails fund={fund} onClick={() => onSubmit(fund)} />
                </Card>
              )),
            )}
        </div>
      )}
    </>
  );
};
