import { z } from 'zod';

import type { StoredTransaction, TransactionActionKey } from '@endaoment-frontend/blockchain-interactions';
import {
  addressSchema,
  bigIntSchema,
  entityLabelSchema,
  entityTypeSchema,
  evmTokenSchema,
  genericTokenSchema,
  otcTokenSchema,
  pledgeOutcomeSchema,
  portfolioTradeTypeSchema,
  stockTickerSchema,
  timestampSchema,
  uuidSchema,
  type Address,
} from '@endaoment-frontend/types';

export type TransactionAsActivity = Omit<StoredTransaction, 'failCount' | 'type'> & {
  transactionType: TransactionActionKey;
  type: 'transaction';
  occurredAtUtc: number;
  transactor: Address;
  transactionHash: Address;
  automated: false;
};
export type UserActivity = Activity | TransactionAsActivity;

export const activitySubjectSchema = z.enum([...entityTypeSchema.options, 'user', 'featured']);
export type ActivitySubject = z.infer<typeof activitySubjectSchema>;

const genericActivitySchema = z.object({
  transactor: addressSchema,
  /** A timestamp */
  occurredAtUtc: timestampSchema,
  transactionHash: addressSchema.nullish(),
  type: z.string(),
  automated: z.boolean(),
  chainId: z.number(),
});
const valueTransferActivitySchema = genericActivitySchema.extend({
  // Can be either token amount or share amount
  amount: bigIntSchema,
  usdcAmount: bigIntSchema,
});

const customActivitySchema = z.object({
  type: z.literal('custom'),
  content: z.string(),
  /** A timestamp */
  occurredAtUtc: timestampSchema,
});

const donationActivitySchema = valueTransferActivitySchema.extend({
  type: z.literal('donation'),
  to: entityLabelSchema,
  token: z.union([genericTokenSchema, evmTokenSchema, otcTokenSchema]),
});

const pledgeActivitySchema = genericActivitySchema.extend({
  transactor: addressSchema.nullish(),
  to: entityLabelSchema,
  pledgeId: uuidSchema,
  outcome: pledgeOutcomeSchema,
  chainId: z.number().nullish(),
});

const stockPledgeActivitySchema = pledgeActivitySchema.extend({
  type: z.literal('stock_donation_pledge'),
  stock: stockTickerSchema.extend({ id: uuidSchema }),
  usdcAmount: bigIntSchema,
});

const fiatPledgeActivitySchema = pledgeActivitySchema.extend({
  type: z.enum(['fiat_donation_pledge', 'migration_donation_pledge']),
  usdcAmount: bigIntSchema,
});

const necPledgeActivitySchema = pledgeActivitySchema.extend({
  type: z.literal('nec_donation_pledge'),
  amount: bigIntSchema,
  usdcAmount: bigIntSchema.nullish(),
  token: z.union([otcTokenSchema, evmTokenSchema]),
});

const allocationActivitySchema = genericActivitySchema.extend({
  type: z.literal('portfolio_trade'),
  fund: entityLabelSchema,
  tradeType: portfolioTradeTypeSchema,
  usdcAmount: bigIntSchema,
  portfolio: z.object({
    name: z.string(),
    id: uuidSchema,
    ticker: z.string().nullish(),
  }),
  shares: bigIntSchema.nullish(),
  outcome: z.enum(['InTransit', 'Completed']),
});
const transferActivitySchema = valueTransferActivitySchema.extend({
  type: z.enum(['grant', 'internal_transfer']),
  from: entityLabelSchema,
  to: entityLabelSchema,
  token: z.union([genericTokenSchema, evmTokenSchema, otcTokenSchema]),
});
const membershipRewardActivitySchema = genericActivitySchema.extend({
  type: z.literal('reward'),
  // Amount of tokens rewarded
  rewardAmount: bigIntSchema,
  // Descriptor for this action/reward
  descriptor: z.string(),
  // The dollar amount of the reward was based on
  dollarBasis: bigIntSchema.nullish(),
  // Cadence of the reward
  cadence: z.enum(['Daily', 'Weekly', 'Monthly']).nullish(),
});

export type DonationActivity = z.infer<typeof donationActivitySchema>;
export type StockPledgeActivity = z.infer<typeof stockPledgeActivitySchema>;
export type FiatPledgeActivity = z.infer<typeof fiatPledgeActivitySchema>;
export type NecPledgeActivity = z.infer<typeof necPledgeActivitySchema>;
export type AllocationActivity = z.infer<typeof allocationActivitySchema>;
export type TransferActivity = z.infer<typeof transferActivitySchema>;
export type MembershipRewardActivity = z.infer<typeof membershipRewardActivitySchema>;
export type CustomActivity = z.infer<typeof customActivitySchema>;

export const activitySchema = z.discriminatedUnion('type', [
  customActivitySchema,
  donationActivitySchema,
  stockPledgeActivitySchema,
  fiatPledgeActivitySchema,
  necPledgeActivitySchema,
  allocationActivitySchema,
  transferActivitySchema,
  membershipRewardActivitySchema,
]);
export type Activity = z.infer<typeof activitySchema>;

export const donationPledgeActivitySchema = z.union([
  stockPledgeActivitySchema,
  fiatPledgeActivitySchema,
  necPledgeActivitySchema,
]);
export type DonationPledgeActivity = z.infer<typeof donationPledgeActivitySchema>;
