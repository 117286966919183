import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useReducer, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { match } from 'ts-pattern';
import { useDebounce } from 'use-debounce';

import { useIsMounted } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon, CircleIcon, SearchIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import type { SearchSelection } from './SearchResults';
import { SearchResults } from './SearchResults';
import styles from './SimpleSearch.module.scss';
import { useSearch } from './useSearch';

export type SimpleSearchProps = {
  className?: string;
  compressed?: boolean;
  includeLink?: boolean;
  onSelect?: (selection?: SearchSelection) => void;
};

const PAGE_SIZE = 10;

export const SimpleSearch = ({ className, compressed = false, includeLink = true, onSelect }: SimpleSearchProps) => {
  const router = useRouter();

  const [displayResults, setDisplayResults] = useState(false);
  const [{ rawSearchTerm, cleanSearchTerm }, setSearchTerm] = useReducer(
    (
      _prev: {
        rawSearchTerm: string;
        cleanSearchTerm: string;
      },
      rawSearchTerm: string,
    ) => {
      const cleanSearchTerm = rawSearchTerm.trim().toLowerCase();
      setDisplayResults(cleanSearchTerm.length > 0);
      return { rawSearchTerm, cleanSearchTerm };
    },
    {
      rawSearchTerm: '',
      cleanSearchTerm: '',
    },
  );
  const { data, isPending } = useSearch(cleanSearchTerm, { pageSize: PAGE_SIZE });

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isPopoverFocused, setIsPopoverFocused] = useState(false);

  // Ghost focus is used to keep the popover open when the user clicks on an item in the popover
  const [isGhostFocused, setIsGhostFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  useClickAway(inputRef, () => setIsGhostFocused(false));

  const handleSelect = (selection?: SearchSelection) => {
    setDisplayResults(false);
    setIsGhostFocused(false);

    // Navigate
    if (selection?.type === 'org') {
      void router.push(routes.app.org({ einOrId: selection.org.ein ?? selection.org.id }));
    } else if (selection?.type === 'fund') {
      void router.push(routes.app.fund({ id: selection.fund.id }));
    } else {
      void router.push(routes.app.explore({ searchTerm: rawSearchTerm }));
    }

    onSelect?.(selection);
  };

  const shouldOpen = isInputFocused || isPopoverFocused || isGhostFocused;
  const debouncedShouldOpen = useDebounce(shouldOpen, 200)[0] && shouldOpen;

  const [isOrgMode, setIsOrgMode] = useState(true);

  const inputState = match({ debouncedShouldOpen, searchTerm: rawSearchTerm })
    .with({ debouncedShouldOpen: true, searchTerm: '' }, () => 'empty')
    .with({ debouncedShouldOpen: true }, () => 'open')
    .otherwise(() => 'closed');

  if (!isPending && data?.orgs.length === 0 && data?.funds.length > 0 && isOrgMode) {
    if (isOrgMode && data?.orgs.length === 0 && data?.funds.length > 0) {
      setIsOrgMode(false);
    }
    if (!isOrgMode && data?.funds.length === 0 && data?.orgs.length > 0) {
      setIsOrgMode(true);
    }
  }

  const isMounted = useIsMounted();
  if (!isMounted) return <></>;

  const orgToggleButton = (
    <Button
      variation='org'
      size='small'
      filled={isOrgMode}
      onClick={() => setIsOrgMode(true)}
      disabled={data?.orgs.length === 0}
      float={false}
      className={styles['toggle-button']}>
      <CircleIcon />
      Orgs&nbsp;
      <b>{data?.orgs.length >= PAGE_SIZE ? `${PAGE_SIZE}+` : data?.orgs.length ?? 0}</b>
    </Button>
  );
  const fundToggleButton = (
    <Button
      variation='fund'
      size='small'
      filled={!isOrgMode}
      onClick={() => setIsOrgMode(false)}
      disabled={data?.funds.length === 0}
      float={false}
      className={styles['toggle-button']}>
      <StarIcon />
      Funds&nbsp;
      <b>{data?.funds.length >= PAGE_SIZE ? `${PAGE_SIZE}+` : data?.funds.length ?? 0}</b>
    </Button>
  );

  return (
    <Popover
      isOpen={inputState === 'open' && displayResults}
      gutter={0}
      trigger='click'
      matchWidth
      autoFocus={false}
      closeOnBlur={false}>
      <PopoverAnchor>
        <InputGroup
          as={motion.div}
          animate={inputState}
          className={clsx(
            styles.search,
            compressed && styles['search--compressed'],
            shouldOpen && styles['search--focused'],
            className,
          )}
          ref={inputRef}>
          <InputLeftElement>
            {isPending ? (
              <Loader className={styles['search-icon']} />
            ) : (
              <SearchIcon
                className={clsx(styles['search-icon'], styles['search-icon--glass'])}
                width={36}
                color='currentColor'
              />
            )}
          </InputLeftElement>
          <Input
            placeholder={compressed ? 'Search by name or EIN...' : 'Search for nonprofits and community funds...'}
            onFocus={() => {
              setIsInputFocused(true);
              setIsGhostFocused(true);
            }}
            onBlur={() => setIsInputFocused(false)}
            onChange={e => setSearchTerm(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();

                // If there is only one result, select it
                // If not the case, send a generic selection to be used by callback
                match({ isOrgMode, orgCount: data.orgs.length, fundCount: data.funds.length })
                  .with({ isOrgMode: true, orgCount: 1 }, () => handleSelect({ type: 'org', org: data.orgs[0] }))
                  .with({ isOrgMode: false, fundCount: 1 }, () => handleSelect({ type: 'fund', fund: data.funds[0] }))
                  .otherwise(() => handleSelect());
              }
            }}
            value={rawSearchTerm}
            className={clsx(styles['search-input'])}
            data-state={inputState}
          />
          <AnimatePresence mode='wait' presenceAffectsLayout>
            {inputState !== 'closed' && (
              <motion.div
                initial='closed'
                animate='open'
                exit='closed'
                variants={{
                  open: { opacity: 1 },
                  closed: { opacity: 0 },
                }}
                className={clsx(
                  styles['toggle-overlay'],
                  compressed && styles['toggle-overlay--compressed'],
                  styles['filter-buttons'],
                )}>
                {orgToggleButton}
                {fundToggleButton}
              </motion.div>
            )}
          </AnimatePresence>

          {!!includeLink && (
            <InputRightElement
              as={Link}
              href={routes.app.explore({ searchTerm: rawSearchTerm })}
              className={clsx(
                styles['search-icon-container'],
                debouncedShouldOpen && styles['search-icon-container--focused'],
                inputState === 'open' && styles['search-icon-container--open'],
              )}>
              <ArrowIcon className={clsx(styles['search-icon'], styles['search-icon--arrow'])} />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>
      <PopoverContent
        variants={{
          enter: {
            height: 'auto',
            transition: {
              duration: 0.1,
              ease: [0.4, 0, 1, 1],
              delay: 0.3,
            },
          },
          exit: {
            height: 0,
            transition: {
              duration: 0.1,
              ease: [0, 0, 0.2, 1],
            },
          },
        }}
        borderRadius='0 0 1.5rem 1.5rem'
        width='100%'
        border='1px solid var(--highlight-color)'
        borderTop='none'
        onHoverStart={() => setIsPopoverFocused(true)}
        onHoverEnd={() => setIsPopoverFocused(false)}>
        <PopoverBody className={clsx(styles['result-list'], isPending && styles['result-list--loading'])}>
          <Box
            className={clsx(
              styles['mobile-toggle'],
              compressed && styles['mobile-toggle--compressed'],
              styles['filter-buttons'],
            )}>
            {orgToggleButton}
            {fundToggleButton}
          </Box>

          <SearchResults data={data} isPending={isPending} mode={isOrgMode ? 'org' : 'fund'} onSelect={handleSelect} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
