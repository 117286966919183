import { trackEvent } from '@phntms/next-gtm';
import type { ComponentProps, ReactElement } from 'react';
import { useEffect, useReducer, useRef } from 'react';
import { useAsync } from 'react-use';
import { P, match } from 'ts-pattern';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { useEditPosition } from '@endaoment-frontend/blockchain-interactions';
import type { AssembledTradeResponse } from '@endaoment-frontend/types';
import { Loader, StepModal } from '@endaoment-frontend/ui/shared';
import { isViewableBlockchainStatus } from '@endaoment-frontend/utils';

import styles from './PortfolioWizard.module.scss';
import { AmountStep } from './steps/AmountStep';
import { ConfirmStep } from './steps/ConfirmStep';
import { FundStep } from './steps/FundStep';
import { PortfolioStep } from './steps/PortfolioStep';
import { ViewStep } from './steps/ViewStep';
import { usePortfolioWizardState } from './usePortfolioWizardState';

type WizardSteps = 'amount' | 'choose-allocation' | 'choose-fund' | 'confirmation' | 'view';

export const PortfolioWizard = () => {
  const { isSignedIn } = useAuth();
  const { showWallet, isWalletModalOpen } = useWalletModal();

  const { isPortfolioWizardOpen, state, resetPortfolioWizard } = usePortfolioWizardState();
  const shouldShowWallet = !isSignedIn && !isWalletModalOpen;
  const isOpen = isPortfolioWizardOpen && isSignedIn && !isWalletModalOpen;

  const [step, setStep] = useReducer((_prev: WizardSteps, newVal: WizardSteps) => {
    if (isOpen) {
      trackEvent({ event: 'pw_wizard_progress', data: { pw_wizard_step: newVal } });
    }
    return newVal;
  }, 'choose-fund');

  const handleClose = () => {
    setStep('choose-fund');
    resetPortfolioWizard();
    resetTransaction();
  };

  const hasAlreadyReroutedToWallet = useRef(false);
  useAsync(async () => {
    // If the user has already been rerouted to the wallet, close the modal
    if (isPortfolioWizardOpen && shouldShowWallet && hasAlreadyReroutedToWallet.current) {
      handleClose();
      hasAlreadyReroutedToWallet.current = false;
      return;
    }
    // Guard against rendering if the user is not signed in
    if (isPortfolioWizardOpen && shouldShowWallet) {
      showWallet();
      hasAlreadyReroutedToWallet.current = true;
      return;
    }

    setStep(
      match(state)
        .returnType<typeof step>()
        .with(
          { fundId: P.not(P.nullish), portfolioId: P.not(P.nullish), amount: P.bigint.gt(0n) },
          () => 'confirmation',
        )
        .with({ fundId: P.not(P.nullish), portfolioId: P.not(P.nullish) }, () => 'amount')
        .with({ fundId: P.not(P.nullish) }, () => 'choose-allocation')
        .otherwise(() => 'choose-fund'),
    );
  }, [isPortfolioWizardOpen, shouldShowWallet]);

  const {
    execute: editPosition,
    reset: resetTransaction,
    status: transactionStatus,
    transactionHash,
    transactionChainId,
  } = useEditPosition();
  useEffect(() => {
    if (isViewableBlockchainStatus(transactionStatus) && transactionHash && transactionChainId) {
      setStep('view');
    }
  }, [transactionStatus, transactionHash, transactionChainId]);
  const handleInitiateTransaction = async (params: AssembledTradeResponse) => {
    // TODO: Implement recommendationId
    await editPosition({ ...params, recommendationId: undefined });

    // Clear dataLayer before sending ecommerce values
    trackEvent({ data: { ecommerce: null } });
    trackEvent({
      event: `pw_${params.tradeData.tradeType.toLowerCase()}`,
      data: {
        entityId: params.entityId,
        entityAddress: params.entityAddress,
        portfolioId: params.portfolioId,
        portfolioAddress: params.tradeData.portfolioAddress,
        amount: params.tradeData.amount.toString(),
        chainId: params.tradeData.chainId,
        tradeType: params.tradeData.tradeType,
      },
    });
  };

  return (
    <StepModal isOpen={isOpen} onClose={handleClose} className={styles['wizard']}>
      <PortfolioWizardSteps
        step={step}
        setStep={setStep}
        onClose={handleClose}
        onInitiateTransaction={handleInitiateTransaction}
        status={transactionStatus}
        transactionChainId={transactionChainId}
        transactionHash={transactionHash}
      />
    </StepModal>
  );
};

const PortfolioWizardSteps = ({
  step,
  setStep,
  onClose,
  onInitiateTransaction,
  status: transactionStatus,
  transactionChainId,
  transactionHash,
}: Pick<ReturnType<typeof useEditPosition>, 'status' | 'transactionChainId' | 'transactionHash'> & {
  step: WizardSteps;
  setStep: (step: WizardSteps) => void;
  onClose: () => void;
  onInitiateTransaction: (params: AssembledTradeResponse) => void;
}) => {
  const { state, __querySetters } = usePortfolioWizardState();
  const { setFundId, setPortfolioId, setAmount, setIsDeposit } = __querySetters;

  const steps = match({ step, ...state })
    .returnType<ReactElement<ComponentProps<typeof StepModal.Step>>>()
    .with({ step: 'choose-fund' }, ({ step, portfolioId }) => (
      <StepModal.Step key={step} onClose={onClose} header='Portfolio Allocation' progress={{ current: 1, pages: 4 }}>
        <FundStep
          portfolioId={portfolioId}
          onClose={onClose}
          onClearPortfolioSelection={() => {
            setPortfolioId();
          }}
          onFundSelect={fundId => {
            setFundId(fundId);
            if (portfolioId) {
              setStep('amount');
              return;
            }
            setStep('choose-allocation');
          }}
        />
      </StepModal.Step>
    ))
    .with({ fundId: P.nullish }, () => (
      <StepModal.Step key='loading-fund' onClose={onClose} header='Portfolio Allocation'>
        <Loader size='l' />
      </StepModal.Step>
    ))
    .with({ step: 'choose-allocation' }, ({ step, fundId }) => {
      if (!fundId) throw new Error(`Fund ID is missing from Portfolio Wizard ${step} step`);
      return (
        <StepModal.Step
          key={step}
          onClose={onClose}
          onBack={() => {
            setStep('choose-fund');
          }}
          header='Portfolio Allocation'
          progress={{ current: 2, pages: 4 }}>
          <PortfolioStep
            fundId={fundId}
            onSelect={portfolioId => {
              setPortfolioId(portfolioId);
              setStep('amount');
            }}
          />
        </StepModal.Step>
      );
    })
    .with({ step: 'amount' }, ({ step, fundId, portfolioId, amount, isDeposit }) => {
      if (!fundId) throw new Error(`Fund ID is missing from Portfolio Wizard ${step} step`);
      if (!portfolioId) throw new Error(`Portfolio ID is missing from Portfolio Wizard ${step} step`);
      return (
        <StepModal.Step
          key={step}
          onClose={onClose}
          onBack={() => {
            setStep('choose-allocation');
          }}
          header='Allocation Details'
          progress={{ current: 3, pages: 4 }}>
          <AmountStep
            fundId={fundId}
            portfolioId={portfolioId}
            initialValues={{ amount, isDeposit }}
            onIsDepositChange={setIsDeposit}
            onSubmit={v => {
              setAmount(v.amount);
              setIsDeposit(v.isDeposit);
              setStep('confirmation');
            }}
          />
        </StepModal.Step>
      );
    })
    .with({ step: 'confirmation' }, ({ step, fundId, portfolioId, amount, isDeposit }) => {
      if (!fundId) throw new Error(`Fund ID is missing from Portfolio Wizard ${step} step`);
      if (!portfolioId) throw new Error(`Portfolio ID is missing from Portfolio Wizard ${step} step`);
      if (!amount) throw new Error(`Amount is missing from Portfolio Wizard ${step} step`);
      return (
        <StepModal.Step
          key={step}
          onClose={onClose}
          onBack={() => {
            setStep('amount');
          }}
          header='Allocation Details'>
          <ConfirmStep
            fundId={fundId}
            portfolioId={portfolioId}
            amount={amount}
            isDeposit={isDeposit}
            confirmStatus={transactionStatus}
            onConfirm={onInitiateTransaction}
            onDeselectPortfolio={() => {
              setPortfolioId();
              setStep('choose-allocation');
            }}
            onDeselectFund={() => {
              setFundId();
              setStep('choose-fund');
            }}
          />
        </StepModal.Step>
      );
    })
    .with({ step: 'view' }, ({ step, fundId, portfolioId, amount, isDeposit }) => {
      if (!fundId) throw new Error(`Fund ID is missing from Portfolio Wizard ${step} step`);
      if (!portfolioId) throw new Error(`Portfolio ID is missing from Portfolio Wizard ${step} step`);
      if (!amount) throw new Error(`Amount is missing from Portfolio Wizard ${step} step`);
      if (!isViewableBlockchainStatus(transactionStatus) || !transactionHash || !transactionChainId)
        throw new Error(`Transaction is not viewable from Portfolio Wizard ${step} step`);
      return (
        <StepModal.Step key={step} onClose={onClose} header='Allocation Details'>
          <ViewStep
            fundId={fundId}
            portfolioId={portfolioId}
            amount={amount}
            isDeposit={isDeposit}
            status={transactionStatus}
            transactionHash={transactionHash}
            transactionChainId={transactionChainId}
          />
        </StepModal.Step>
      );
    })
    .exhaustive();

  return <StepModal.StepsWrapper>{steps}</StepModal.StepsWrapper>;
};
