import { P, match } from 'ts-pattern';

import { GetSwapInfo } from '@endaoment-frontend/api';
import { useAuthType } from '@endaoment-frontend/authentication';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@endaoment-frontend/constants';
import { getChainNameForChainId } from '@endaoment-frontend/multichain';
import { TargetAllocationRebalanceCheckbox } from '@endaoment-frontend/target-allocations';
import type { DonationRecipient, EVMToken, TransactionStatus } from '@endaoment-frontend/types';
import { ChainIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatPriceImpactToPercent, formatUsdc } from '@endaoment-frontend/utils';

import { TaxReceiptButton } from '../common/TaxReceiptButton';
import styles from '../DonationWizard.module.scss';
import { estimateProceeds, estimateTokenConversionRate, specialFormatTokenNumber } from '../helpers';

export const DonationConfirm = ({
  token,
  tokenAmount,
  chainId,
  transactionStatus,
  rebalanceTransactionStatus,
  onStartTransaction,
  onStartRebalance,
  donorIdentity,
  isRebalanceRequested,
  onChangeRebalanceRequested,
  recipient,
  onGoToTaxStep,
  onNoTaxReceipt,
}: {
  token: EVMToken;
  tokenAmount: bigint;
  chainId: number;
  onStartTransaction: () => void;
  onStartRebalance: () => void;
  transactionStatus: TransactionStatus;
  rebalanceTransactionStatus: TransactionStatus;
  donorIdentity: { taxReceipt: false; donorEmail?: undefined } | { taxReceipt: true; donorEmail: string };
  isRebalanceRequested: boolean;
  onChangeRebalanceRequested: (newVal: boolean) => void;
  recipient: DonationRecipient;
  onGoToTaxStep: () => void;
  onNoTaxReceipt: () => void;
}) => {
  const { isSocialAuth } = useAuthType();

  const { data: swapInfo } = GetSwapInfo.useQuery([tokenAmount, token, recipient, chainId]);

  if (!swapInfo) {
    return <Loader size='l' />;
  }

  const proceeds = estimateProceeds(token, swapInfo.quote);

  return (
    <>
      <div className={styles['donation-info']}>
        <div>
          <h4>Donation</h4>
          <h4>
            {specialFormatTokenNumber(tokenAmount, token)}
            &nbsp;
            {token.symbol}
          </h4>
        </div>
        <div>
          <h4>Estimated Proceeds</h4>
          <h4>{formatCurrency(formatUsdc(proceeds.estimatedProceeds))}</h4>
        </div>
        {!!swapInfo && (
          <div>
            <h4>Network</h4>
            <h4 className={styles['chain-label']}>
              <ChainIcon chainId={swapInfo?.chainId} light />
              {getChainNameForChainId(swapInfo?.chainId)}
            </h4>
          </div>
        )}
        <div>
          <h6>Price</h6>
          <h6>
            {estimateTokenConversionRate({ token, swapInfo })}
            &nbsp;{token.symbol}&nbsp;/&nbsp;USDC
          </h6>
        </div>
        <div>
          <h6>Min deposited</h6>
          <h6>{formatCurrency(formatUsdc(proceeds.estimatedMinProceeds))}</h6>
        </div>
        <div>
          <h6>Price impact</h6>
          <h6>{formatPriceImpactToPercent(swapInfo.quote.priceImpact)}</h6>
        </div>
        <div>
          <h6>Uniswap fee</h6>
          <h6>{formatCurrency(formatUsdc(proceeds.estimatedUniswapFee))}</h6>
        </div>
        <div>
          <h6>Endaoment fee</h6>
          <h6>{formatCurrency(formatUsdc(swapInfo.quote.endaomentFee))}</h6>
        </div>
        <TargetAllocationRebalanceCheckbox
          isRebalanceRequested={isRebalanceRequested}
          onChange={onChangeRebalanceRequested}
          recipient={recipient}
          additionalBalance={proceeds.estimatedMinProceeds}
        />
      </div>
      <TaxReceiptButton
        includeTaxReceipt={donorIdentity.taxReceipt}
        receiptEmail={donorIdentity.donorEmail}
        onClick={nextStep => {
          if (!nextStep) {
            onNoTaxReceipt();
            return;
          }
          onGoToTaxStep();
        }}
      />
      <div className={styles['send-transaction']}>
        {match({ transactionStatus, rebalanceTransactionStatus, isRebalanceRequested })
          .with({ transactionStatus: P.union('none', 'rejected', 'error'), isRebalanceRequested: true }, () => (
            <Button
              variation='purple'
              type='submit'
              filled
              onClick={onStartTransaction}
              size='medium'
              float={false}
              id='final_confirmation'
              data-testid='confirm-send-transaction'>
              Donate (1/2)
            </Button>
          ))
          .with({ transactionStatus: P.union('none', 'rejected', 'error'), isRebalanceRequested: false }, () => (
            <Button
              variation='purple'
              type='submit'
              filled
              onClick={onStartTransaction}
              size='medium'
              float={false}
              id='final_confirmation'
              data-testid='confirm-send-transaction'>
              Donate
            </Button>
          ))
          .with({ transactionStatus: 'waiting' }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              {isSocialAuth ? 'Processing request...' : 'Please sign transaction in wallet...'}
            </Button>
          ))
          .with({ transactionStatus: 'pending' }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              Processing request...
            </Button>
          ))
          .with(
            {
              transactionStatus: 'success',
              rebalanceTransactionStatus: P.union('none', 'rejected', 'error'),
              isRebalanceRequested: true,
            },
            () => (
              <Button
                variation='purple'
                type='submit'
                filled
                onClick={onStartRebalance}
                size='medium'
                float={false}
                id='final_confirmation'
                data-testid='confirm-send-transaction'>
                Donate (2/2)
              </Button>
            ),
          )
          .with({ rebalanceTransactionStatus: 'waiting', isRebalanceRequested: true }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              {isSocialAuth ? 'Processing request...' : 'Please sign transaction in wallet...'}
            </Button>
          ))
          .with({ rebalanceTransactionStatus: 'pending' }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              Processing request...
            </Button>
          ))
          .otherwise(() => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
            </Button>
          ))}
        {transactionStatus === 'rejected' ||
          (rebalanceTransactionStatus === 'rejected' && <span>Transaction cancelled by user</span>)}
        <p>
          Agree to Endaoment's <a href={PRIVACY_POLICY_URL}>privacy policy</a> and{' '}
          <a href={TERMS_AND_CONDITIONS_URL}>terms and conditions</a>
        </p>
      </div>
    </>
  );
};
