import { Skeleton } from '@chakra-ui/react';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { AssembleTrade, GetPortfolio, RegisterTrade } from '@endaoment-frontend/api';
import { useTransactionWithTimestamp } from '@endaoment-frontend/blockchain-interactions';
import { defaults } from '@endaoment-frontend/config';
import { PRICE_IMPACT_WARNING_THRESHOLD } from '@endaoment-frontend/constants';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import type { Address, BlockchainTransactionStatus, UUID } from '@endaoment-frontend/types';
import { InTransitIcon, ShareIcon2 } from '@endaoment-frontend/ui/icons';
import { Button, Card, Loader } from '@endaoment-frontend/ui/shared';
import { FundAllocationBarWithQuery } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatDate, formatPriceImpactToPercent, formatUsdc } from '@endaoment-frontend/utils';

import { RemovableFundDetails, RemoveablePortfolioDetails } from '../common/RemovableDetails';
import wizardStyles from '../PortfolioWizard.module.scss';

import { DashedBorder } from './AmountStep';
import styles from './ViewStep.module.scss';

export const ViewStep = ({
  fundId,
  portfolioId,
  amount,
  isDeposit,
  status,
  transactionHash,
  transactionChainId,
}: {
  fundId: UUID;
  portfolioId: UUID;
  amount: bigint;
  isDeposit: boolean;
  status: BlockchainTransactionStatus;
  transactionHash: Address;
  transactionChainId: number;
}) => {
  // TODO: See if we can get the portfolio type from the trade registration instead
  const { data: portfolio } = GetPortfolio.useQuery([portfolioId]);
  const { data: assembledTrade } = AssembleTrade.useQuery([
    {
      issuerEntityId: fundId,
      issuerEntityType: 'fund',
      portfolioId: portfolioId,
      amountUsdc: amount,
      tradeType: isDeposit ? 'Buy' : 'Sell',
    },
  ]);
  const { data: completedTrade } = RegisterTrade.useQuery([transactionHash, transactionChainId], {
    // Needs strict refetch policy to ensure that the transaction is not re-registered (even though it should be idempotent)
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    networkMode: 'offlineFirst',
    staleTime: Infinity,
    enabled: status === 'success',
  });

  const { error, timestamp } = useTransactionWithTimestamp(
    transactionHash,
    transactionChainId,
    defaults.confirmations.portfolioTrade,
  );

  const isTradeInTransit =
    status === 'success' && (portfolio?.type === 'TPlusN' || portfolio?.type === 'PrivateWealth');

  return (
    <>
      <ViewAllocation
        outcome={status === 'success' ? (isTradeInTransit ? 'InTransit' : 'Completed') : 'Pending'}
        allocationDetails={{
          entityId: completedTrade?.issuerEntity ?? assembledTrade?.entityId ?? fundId,
          portfolioId: completedTrade?.portfolioId ?? assembledTrade?.portfolioId ?? portfolioId,
          tradeType: completedTrade?.type ?? assembledTrade?.tradeData.tradeType ?? (isDeposit ? 'Buy' : 'Sell'),
          amount: completedTrade?.amount ?? amount,
          previousPosition: assembledTrade?.estimations.previousPositionUsdc,
          newGrantableBalance: assembledTrade?.estimations.estimatedNewGrantableBalanceUsdc,
          minNewGrantableBalance: assembledTrade?.estimations.minimumNewGrantableBalanceUsdc ?? undefined,
          minNewEstimatedPosition: assembledTrade?.estimations.minimumNewEstimatedPositionUsdc ?? undefined,
          newPosition: assembledTrade?.estimations.newEstimatedPositionUsdc,
          endaomentFee: completedTrade?.fee ?? assembledTrade?.estimations.estimatedFee,
          priceImpact: assembledTrade?.tradeData.priceImpact ?? undefined,
        }}
      />
      <div className={styles['transaction-status-section']}>
        {match(status)
          .when(
            () => isTradeInTransit,
            () => (
              <div className={styles['transit-label']}>
                <p>
                  <InTransitIcon className={styles['transit-icon']} />
                  In Transit...
                </p>
                <p>
                  We are currently processing this allocation.
                  <br />
                  This may take up to 48 hours.
                </p>
              </div>
            ),
          )
          .with('success', () => (
            <div>
              Allocation completed:
              {timestamp !== undefined && (
                <span>{formatDate(timestamp, { includeTime: true, dateStyle: 'short' })}</span>
              )}
            </div>
          ))
          .with('error', () => (
            <div>
              <b>Transaction failure</b>
              <p>{error?.message}</p>
            </div>
          ))
          .otherwise(() => (
            <div>
              <Loader size='s' />
              Allocating...
            </div>
          ))}
        {!!transactionHash && transactionChainId !== undefined && (
          <Button
            as='a'
            href={getTransactionLink(transactionHash, transactionChainId)}
            size='small'
            float={false}
            filled
            target='_blank'>
            View Transaction <ShareIcon2 color='currentColor' />
          </Button>
        )}
      </div>
    </>
  );
};

export const ViewAllocation = ({
  outcome,
  allocationDetails,
  onDeselectFund,
  onDeselectPortfolio,
}: {
  outcome: 'Completed' | 'InTransit' | 'Pending';
  allocationDetails: {
    entityId: UUID;
    portfolioId: UUID;
    tradeType: 'Buy' | 'Sell';
    amount: bigint;
    previousPosition?: bigint;
    newGrantableBalance?: bigint;
    minNewGrantableBalance?: bigint;
    minNewEstimatedPosition?: bigint;
    newPosition?: bigint;
    endaomentFee?: bigint;
    priceImpact?: number;
  };
  onDeselectPortfolio?: () => void;
  onDeselectFund?: () => void;
}) => {
  const {
    entityId,
    portfolioId,
    amount,
    tradeType,
    endaomentFee,
    newGrantableBalance,
    minNewGrantableBalance,
    minNewEstimatedPosition,
    newPosition,
    previousPosition,
    priceImpact,
  } = allocationDetails;
  const isTransactionComplete = outcome === 'InTransit' || outcome === 'Completed';
  const isPriceImpactOverThreshold = priceImpact !== undefined && priceImpact > PRICE_IMPACT_WARNING_THRESHOLD;

  return (
    <>
      <span className={styles['label']}>Portfolio</span>
      <Card noPadding noShadow>
        <RemoveablePortfolioDetails portfolioId={portfolioId} onRemove={onDeselectPortfolio} />
      </Card>
      <span className={styles['label']}>{!isTransactionComplete && 'Estimated '}New Allocation</span>
      <Card noPadding noShadow className={wizardStyles['extended-card']}>
        <RemovableFundDetails fundId={entityId} onRemove={onDeselectFund} />
        <FundAllocationBarWithQuery
          fundId={entityId}
          portfolioId={portfolioId}
          positionChange={!isTransactionComplete ? amount : undefined}
          estimatedNewGrantable={!isTransactionComplete ? newGrantableBalance : undefined}
          isDeposit={tradeType === 'Buy'}
          isAccurate
        />
      </Card>
      <hr />
      <div>
        <span className={styles['label']}>Allocation Change Details</span>
        <div className={clsx(wizardStyles['estimation-container'], wizardStyles['estimation-container--light'])}>
          <DashedBorder />
          <div data-estimation-type='previous-position'>
            <span>Previous Position</span>
            <Skeleton as='b' isLoaded={previousPosition !== undefined} data-testid='previous-position'>
              {previousPosition !== undefined && formatCurrency(formatUsdc(previousPosition))}
            </Skeleton>
          </div>
          <hr />
          <div data-estimation-type={`${tradeType.toLowerCase()}-amount`}>
            <span>{tradeType}</span>
            <b data-testid='amount'>{formatCurrency(formatUsdc(amount))}</b>
          </div>
          <hr />
          <div data-estimation-type='fee'>
            <span>Endaoment Fee</span>
            <Skeleton as='b' isLoaded={endaomentFee !== undefined} data-testid='fee'>
              {endaomentFee !== undefined && formatCurrency(formatUsdc(endaomentFee))}
            </Skeleton>
          </div>
        </div>
        <div className={wizardStyles['estimation-container']}>
          <DashedBorder />
          <div data-estimation-type='grantable-balance'>
            <span>{`${isTransactionComplete ? '' : 'Estimated '}New Grantable Balance`}</span>
            <Skeleton as='b' data-testid='grantable-balance' isLoaded={newGrantableBalance !== undefined}>
              {newGrantableBalance !== undefined && formatCurrency(formatUsdc(newGrantableBalance))}
            </Skeleton>
          </div>
          {!isTransactionComplete && !!isPriceImpactOverThreshold && minNewGrantableBalance !== undefined && (
            <>
              <hr />
              <div data-estimation-type='min-grantable-balance'>
                <span>Minimum New Grantable Balance</span>
                <b data-testid='min-grantable-balance'>{formatCurrency(formatUsdc(minNewGrantableBalance))}</b>
              </div>
            </>
          )}
          <hr />
          <div data-estimation-type='allocation'>
            <span>{`${isTransactionComplete ? '' : 'Estimated '}New Position`}</span>
            <Skeleton as='b' isLoaded={newPosition !== undefined} data-testid='allocation'>
              {newPosition !== undefined && formatCurrency(formatUsdc(newPosition))}
            </Skeleton>
          </div>
          {!isTransactionComplete && !!isPriceImpactOverThreshold && minNewEstimatedPosition !== undefined && (
            <>
              <hr />
              <div data-estimation-type='min-allocation'>
                <span>Minimum New Position</span>
                <b data-testid='min-new-position'>{formatCurrency(formatUsdc(minNewEstimatedPosition))}</b>
              </div>
            </>
          )}
          {!isTransactionComplete && !!isPriceImpactOverThreshold && (
            <>
              <hr />
              <div data-estimation-type='price-impact'>
                <span>Price Impact</span>
                <b>{formatPriceImpactToPercent(priceImpact)}</b>
              </div>
            </>
          )}
        </div>
      </div>
      {!isTransactionComplete && !!isPriceImpactOverThreshold && (
        <p className={wizardStyles['price-impact']}>
          Due to price impact and slippage impact total portfolio values might decrease upon allocation changes.
        </p>
      )}
    </>
  );
};
