import { match, P } from 'ts-pattern';

import {
  AwaitingIcon,
  CheckmarkIcon,
  ErrorIcon,
  InTransitIcon,
  RewardIcon,
  RoutingIcon,
} from '@endaoment-frontend/ui/icons';
import { Loader, Tooltip } from '@endaoment-frontend/ui/shared';

import { ActivityDescription } from './activity-items/ActivityDescription';
import { DirectDonationActivityDescription } from './activity-items/DirectDonationActivity';
import { DonationPledgeActivityDescription } from './activity-items/DonationPledgeActivity';
import { InternalTransferActivityDescription } from './activity-items/InternalTransferActivity';
import { PortfolioAllocationActivityDescription } from './activity-items/PortfolioAllocationActivity';
import { RewardActivity } from './activity-items/RewardActivity';
import { TransactionActivityDescription } from './activity-items/TransactionActivityDescription';
import styles from './MatchedActivity.module.scss';
import type { ActivitySubject, UserActivity } from './types';

export const MatchedActivityDescription = ({
  activity,
  subject,
}: {
  activity: UserActivity;
  subject?: ActivitySubject;
}) =>
  match(activity)
    .with({ type: 'donation' }, activity => <DirectDonationActivityDescription subject={subject} activity={activity} />)
    .with({ type: P.union('grant', 'internal_transfer') }, activity => (
      <InternalTransferActivityDescription subject={subject} activity={activity} />
    ))
    .with({ type: 'portfolio_trade' }, activity => (
      <PortfolioAllocationActivityDescription subject={subject} activity={activity} />
    ))
    .with(
      {
        type: P.union(
          'fiat_donation_pledge',
          'migration_donation_pledge',
          'nec_donation_pledge',
          'stock_donation_pledge',
        ),
      },
      activity => <DonationPledgeActivityDescription subject={subject} activity={activity} />,
    )
    .with({ type: 'transaction' }, activity => <TransactionActivityDescription activity={activity} />)
    .with({ type: 'custom' }, activity => <ActivityDescription>{activity.content}</ActivityDescription>)
    .with({ type: 'reward' }, activity => <RewardActivity activity={activity} subject={subject} />)
    .exhaustive();

const pledgeActivityGuardPattern = P.union(
  'fiat_donation_pledge',
  'migration_donation_pledge',
  'nec_donation_pledge',
  'stock_donation_pledge',
);
export const MatchedActivityStatus = ({ activity, subject }: { activity: UserActivity; subject?: ActivitySubject }) =>
  match({ activity, subject })
    .returnType<JSX.Element>()
    .with({ activity: { type: 'reward' } }, () => (
      <RewardIcon className={styles['activity-status']} data-status='reward' width={32} height={32} />
    ))
    .with({ activity: { type: 'portfolio_trade', outcome: 'InTransit' } }, () => (
      <Tooltip content='This transaction is in transit, it might require 24-48 hours for processing.' as='em'>
        <InTransitIcon className={styles['activity-status']} data-status='transit' />
      </Tooltip>
    ))
    .with({ subject: 'user', activity: { type: 'transaction', status: 'pending' } }, () => (
      <Loader size='s' className={styles['activity-status']} data-status='loading' />
    ))
    .with({ subject: 'user', activity: { type: 'transaction' } }, () => (
      <ErrorIcon className={styles['activity-status']} data-status='error' />
    ))
    .with({ subject: 'user', activity: { type: pledgeActivityGuardPattern, outcome: 'Failure' } }, () => (
      <ErrorIcon width={18} height={18} className={styles['activity-status']} data-status='error' />
    ))
    .with({ subject: 'user', activity: { type: pledgeActivityGuardPattern, outcome: 'Pending' } }, () => (
      <Loader size='s' className={styles['activity-status']} data-status='loading' />
    ))
    .with({ subject: 'user', activity: { type: pledgeActivityGuardPattern, outcome: 'Success' } }, () => (
      <CheckmarkIcon
        className={styles['activity-status']}
        data-status='success'
        strokeWidth={3}
        width={32}
        height={32}
      />
    ))
    .with({ subject: 'user', activity: { type: pledgeActivityGuardPattern, outcome: 'AwaitingAssets' } }, () => (
      <Tooltip
        content='We are waiting to receive your assets, view receipt for more info'
        as='b'
        className={styles['activity-status']}
        data-status='awaiting'>
        <AwaitingIcon width={32} />
      </Tooltip>
    ))
    .with({ subject: 'user', activity: { type: pledgeActivityGuardPattern, outcome: 'OnRamping' } }, () => (
      <Tooltip
        content='We are in the process of putting funds onchain, view receipt for more info'
        as='b'
        className={styles['activity-status']}
        data-status='routing'>
        <RoutingIcon />
      </Tooltip>
    ))
    .with({ subject: 'user' }, () => (
      <CheckmarkIcon
        className={styles['activity-status']}
        data-status='success'
        strokeWidth={3}
        width={32}
        height={32}
      />
    ))
    .with({ activity: { type: pledgeActivityGuardPattern, outcome: 'OnRamping' } }, () => (
      <Tooltip
        content='We are in the process of putting funds onchain'
        as='b'
        className={styles['activity-status']}
        data-status='routing'>
        <RoutingIcon />
      </Tooltip>
    ))
    .with({ activity: { type: pledgeActivityGuardPattern, outcome: 'AwaitingAssets' } }, () => (
      <b className={styles['activity-status']} data-status='awaiting'>
        <AwaitingIcon width={32} />
      </b>
    ))
    .with({ activity: { type: pledgeActivityGuardPattern, outcome: 'Pending' } }, () => (
      <Loader size='s' className={styles['activity-status']} data-status='loading' />
    ))
    .otherwise(() => <></>);
