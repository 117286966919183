import type { MutationStatus } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import { useChainId } from 'wagmi';

import { GetFund } from '@endaoment-frontend/api';
import { useAuthType } from '@endaoment-frontend/authentication';
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@endaoment-frontend/constants';
import { TargetAllocationRebalanceCheckbox } from '@endaoment-frontend/target-allocations';
import type { DonationRecipient, GrantInstructionInput, TransactionStatus, UUID } from '@endaoment-frontend/types';
import { Checkbox } from '@endaoment-frontend/ui/forms';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatUsdc, isUnsentStatus } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';
import { RecommendButton } from '../common/RecommendButton';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

import { useTransferFee } from './useTransferFee';

export const GrantConfirmationStep = ({
  onSubmit,
  onRecommend,
  onStartRebalance,
  originFundId,
  destination,
  onRemoveOrigin,
  onRemoveDestination,
  grantInstructions,
  onOffsetFees,
  grantAmount = 0n,
  feeIsOffset,
  transactionStatus,
  calculatedOffsetFeeAmount,
  isRebalanceRequested,
  onChangeRebalanceRequested,
  rebalanceTransactionStatus,
  recommendStatus,
}: {
  originFundId: UUID;
  destination: DonationRecipient;
  grantInstructions: GrantInstructionInput;
  grantAmount?: bigint;
  calculatedOffsetFeeAmount?: bigint;
  feeIsOffset: boolean;
  transactionStatus: TransactionStatus;
  rebalanceTransactionStatus: TransactionStatus;
  onSubmit: () => void;
  onRecommend: () => void;
  onStartRebalance: () => void;
  onRemoveOrigin?: () => void;
  onRemoveDestination?: () => void;
  onOffsetFees: () => void;
  isRebalanceRequested: boolean;
  onChangeRebalanceRequested: (newVal: boolean) => void;
  recommendStatus: MutationStatus;
}) => {
  const { isSocialAuth } = useAuthType();

  const { data: fund } = GetFund.useQuery([originFundId], { enabled: !!originFundId });
  const isUserFundCollaborator = useIsFundCollaborator(originFundId);

  const chainId = useChainId();
  const { data: computedTransferFee, isPending: isPendingGrantFee } = useTransferFee({
    sender: fund?.v2ContractAddress,
    recipient: destination,
    chainId,
    transferAmount: grantAmount,
    enabled: !!fund,
  });

  const grantFee = destination.type === 'org' ? computedTransferFee : 0n;

  const estimatedProceeds = grantAmount - grantFee;

  const canOffsetFees = fund && fund.usdcBalance >= (calculatedOffsetFeeAmount ?? 0n);
  const canSubmit = estimatedProceeds > 0;

  return (
    <>
      <EntityCardWithLabel
        label='Granting from'
        entity={{ type: 'fund', id: originFundId }}
        onRemove={onRemoveOrigin}
      />
      <EntityCardWithLabel label='Granting to' entity={destination} onRemove={onRemoveDestination} />
      <hr />
      <div className={styles['donation-info']}>
        {!isPendingGrantFee ? (
          <>
            <div>
              <h4>Grant</h4>
              <h4>{formatCurrency(formatUsdc(grantAmount))}</h4>
            </div>
            <div>
              <h4>Estimated Proceeds</h4>
              <h4>{formatCurrency(formatUsdc(estimatedProceeds))}</h4>
            </div>
            {match(destination)
              .with({ type: 'fund' }, destination => (
                <TargetAllocationRebalanceCheckbox
                  isRebalanceRequested={isRebalanceRequested}
                  onChange={onChangeRebalanceRequested}
                  recipient={destination}
                  additionalBalance={estimatedProceeds}
                />
              ))
              .with({ type: 'org' }, () => (
                <>
                  <div>
                    <h6>Endaoment Fee</h6>
                    <h6>{formatCurrency(formatUsdc(grantFee))}</h6>
                  </div>
                  <Checkbox
                    checked={feeIsOffset}
                    onChange={onOffsetFees}
                    label='Offset Fees'
                    disabled={!canOffsetFees || !(isUnsentStatus(transactionStatus) || transactionStatus === 'error')}
                    className={styles['offset-fees']}
                  />
                </>
              ))
              .exhaustive()}
          </>
        ) : (
          <Loader size='l' />
        )}
      </div>

      <hr />

      <div className={styles['instruction-info']}>
        <label>Recommender</label>
        <p>{grantInstructions.recommender}</p>
        {grantInstructions.specialInstructions ? (
          <>
            <label>Restricted Grant Instructions</label>
            <p>{grantInstructions.specialInstructions}</p>
          </>
        ) : (
          <>
            <label>Grant Purpose</label>
            <p>{grantInstructions.purpose}</p>
          </>
        )}
      </div>

      <div className={styles['send-transaction']}>
        {match({
          transactionStatus,
          rebalanceTransactionStatus,
          isRebalanceRequested,
          isUserFundCollaborator,
        })
          .with({ isUserFundCollaborator: true }, () => (
            <RecommendButton onRecommend={onRecommend} recommendStatus={recommendStatus} />
          ))
          .with({ transactionStatus: P.union('none', 'rejected', 'error'), isRebalanceRequested: true }, () => (
            <Button
              variation='purple'
              type='submit'
              filled
              onClick={onSubmit}
              size='medium'
              float={false}
              disabled={!canSubmit}>
              Recommend Grant (1/2)
            </Button>
          ))
          .with({ transactionStatus: P.union('none', 'rejected', 'error'), isRebalanceRequested: false }, () => (
            <Button
              variation='purple'
              type='submit'
              filled
              onClick={onSubmit}
              size='medium'
              float={false}
              disabled={!canSubmit}>
              Recommend Grant
            </Button>
          ))
          .with({ transactionStatus: 'waiting' }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              {isSocialAuth ? 'Processing request...' : 'Please sign transaction in wallet...'}
            </Button>
          ))
          .with({ transactionStatus: 'pending' }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              Processing request...
            </Button>
          ))
          .with(
            {
              transactionStatus: 'success',
              rebalanceTransactionStatus: P.union('none', 'rejected', 'error'),
              isRebalanceRequested: true,
            },
            () => (
              <Button
                variation='purple'
                type='submit'
                filled
                onClick={onStartRebalance}
                size='medium'
                float={false}
                disabled={!canSubmit}>
                Recommend Grant (2/2)
              </Button>
            ),
          )
          .with({ rebalanceTransactionStatus: 'waiting', isRebalanceRequested: true }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              {isSocialAuth ? 'Processing request...' : 'Please sign transaction in wallet...'}
            </Button>
          ))
          .with({ rebalanceTransactionStatus: 'pending' }, () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              Processing request...
            </Button>
          ))
          .otherwise(() => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
            </Button>
          ))}
        {transactionStatus === 'rejected' ||
          (rebalanceTransactionStatus === 'rejected' && <span>Transaction cancelled by user</span>)}
        <p>
          By proceeding, you agree to Endaoment's <a href={PRIVACY_POLICY_URL}>privacy policy</a> and{' '}
          <a href={TERMS_AND_CONDITIONS_URL}>terms & conditions</a>
        </p>
      </div>
    </>
  );
};
