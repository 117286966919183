import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type RefObject } from 'react';

import { useAccountDisplayName, useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { useTransactionList } from '@endaoment-frontend/blockchain-interactions';
import { routes } from '@endaoment-frontend/routes';
import { ExploreActionIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import dashboardIcon from '../../images/navbar/dashboard.svg';
import exploreIcon from '../../images/navbar/explore.svg';
import portfoliosIcon from '../../images/navbar/portfolios.svg';
import { ActionPrompt } from '../ActionPrompt';

import { NavLink, WalletAuthText, computeIsWalletButtonSkeletonLoaded } from './CommonNavBarItems';
import styles from './NavBar.module.scss';

type ClientNavItemsProps = {
  promptContainerRef: RefObject<HTMLDivElement>;
  onOpenUserProfile: () => void;
};

export const ClientNavItems = ({ promptContainerRef, onOpenUserProfile }: ClientNavItemsProps) => {
  const { asPath } = useRouter();

  const authState = useAuth();
  const { isSignedIn } = authState;
  const { accountName } = useAccountDisplayName();
  const { showWallet } = useWalletModal();

  const isWalletButtonSkeletonLoaded = computeIsWalletButtonSkeletonLoaded(authState, accountName);

  const pendingCount = useTransactionList().pendingTransactions.length;

  return (
    <>
      <div
        role='button'
        className={clsx(
          styles['wallet-status'],
          !isWalletButtonSkeletonLoaded || isSignedIn
            ? styles['wallet-status--connected']
            : styles['wallet-status--disconnected'],
        )}
        onClick={() => (isSignedIn ? onOpenUserProfile() : showWallet())}>
        <WalletAuthText isWalletButtonSkeletonLoaded={isWalletButtonSkeletonLoaded} />
      </div>
      {pendingCount > 0 && (
        <Button
          as={Link}
          float={false}
          href={routes.app.dashboard()}
          className={styles['pending-transactions']}
          aria-label={`${pendingCount} pending transactions`}
          data-testid='pending-transactions-loader'>
          <Loader />
          {pendingCount}
        </Button>
      )}
      <NavLink
        label='Dashboard'
        icon={<Image src={dashboardIcon} alt='' width={24} height={24} priority />}
        href={routes.app.dashboard()}
        current={asPath}
      />
      <NavLink
        label='Portfolios'
        icon={<Image src={portfoliosIcon} alt='' width={24} height={24} priority />}
        href={routes.app.portfolios()}
        current={asPath}
      />
      <ActionPrompt
        role='link'
        promptKey='explore'
        placement='bottom-end'
        promptContent={
          <>
            <ExploreActionIcon color='currentColor' width={36} />
            <span>Looking for a nonprofit? Check out our expanded explore page.</span>
          </>
        }
        containerRef={promptContainerRef}
        triggered={!isSignedIn && asPath === routes.app.dashboard()}
        float={false}>
        <NavLink
          label='Explore'
          icon={<Image src={exploreIcon} alt='' width={24} height={24} priority />}
          href={routes.app.explore()}
          current={asPath}
          className={styles['explore-link']}
        />
      </ActionPrompt>
    </>
  );
};
